<!-- eslint-disable -->
<script setup>
import { useOddsStore } from '@/stores/odds';

const oddsStore = useOddsStore();

</script>

<template>
	<div class="content-main">
		<div class="title-main">
			<h3>{{ $t('general.odds_format') }}</h3>
		</div>
		<form>
			<div class="lang-options">
				<div  class="radio-btn" @click="oddsStore.changeOddsPreview('decimal')">
				<input :checked="'decimal' == oddsStore.getOddsPreview || oddsStore.getOddsPreview == null" type="radio" name="odds" value="decimal">
				<label>{{ $t('general.decimal') }}</label>
				</div>
				<div class="radio-btn" @click="oddsStore.changeOddsPreview('american')">
					<input :checked="'american' == oddsStore.getOddsPreview" type="radio" name="odds" value="american">
					<label>{{ $t('general.american') }}</label>
				</div>
				<div class="radio-btn" @click="oddsStore.changeOddsPreview('fractional')">
					<input :checked="'fractional' == oddsStore.getOddsPreview" type="radio" name="odds" value="fractional">
					<label>{{ $t('general.fractional') }}</label>
				</div>
			</div>
		</form>
	</div>
</template>