<!-- eslint-disable -->
<script setup>
import { openModal } from '@kolirt/vue-modal';
import Login from '../modals/Login.vue';
import Register from '../modals/Register.vue';
import SportsbookSearch from '../modals/SportsbookSearch.vue';
import SettingDropdown from '../Common/settingDropdown.vue';
import { useNavbarStore } from '@/stores/navbar_items';
import { BetSlip } from '@/stores/BetSlip';
import commonSvgs from '@/assets/svgs/commonSvgs.json';
import { useI18n } from 'vue-i18n';
import NotificationsDropdown from '@/components/Common/notificationsDropdown.vue';

const svgs_data = commonSvgs;
const navbarStore = useNavbarStore();
const betslipStore = BetSlip();
const { t } = useI18n();
const showLoginModal = () => {
	openModal(Login).then((data) => {
		if (data.show_register) showRegisterModal();
	}).catch(() =>{});
};

const showRegisterModal = () => {
	openModal(Register).then((data) => {
		if (data.show_login) showLoginModal();
	}).catch(() =>{});
};

const is_country_ghana = process.env.VUE_APP_COUNTRY == 'GHANA';

const showSportsbookSearchModal = (event) => {
	event.stopPropagation();
	openModal(SportsbookSearch).then((data) => {
	}).catch(() =>{});
		betslipStore.is_betslip_opened = false;
		betslipStore.betSlipModel = '';
		navbarStore.user_dropdown_opened = false;
		navbarStore.settings_dropdown = false;
		navbarStore.mobile_menu = false;
};

const showSettings = () => {
	navbarStore.settings_dropdown = !navbarStore.settings_dropdown;
	navbarStore.user_dropdown_opened = false;
	betslipStore.is_betslip_opened = false;
	betslipStore.betSlipModel = '';
	navbarStore.mobile_menu = false;
};
</script>
<template>
	<div v-if= is_country_ghana class="row-container">
		<div class="header-icons for-ghana">
		<a @click="showSportsbookSearchModal($event)">
			<svg xmlns="http://www.w3.org/2000/svg" width="25.207" height="25.207" viewBox="0 0 25.207 25.207">
				<g id="Icon_feather-search" data-name="Icon feather-search" transform="translate(-1.75 -1.75)">
					<path id="Path_3" data-name="Path 3" d="M24.75,13.5A11.25,11.25,0,1,1,13.5,2.25,11.25,11.25,0,0,1,24.75,13.5Z" transform="translate(0)" fill="none" stroke="#8e9298" stroke-linecap="round" stroke-linejoin="round" stroke-width="1"/>
					<path id="Path_4" data-name="Path 4" d="M17.23,17.225l-4.743-4.737" transform="translate(9.02 9.025)" fill="none" stroke="#8e9298" stroke-linecap="round" stroke-linejoin="round" stroke-width="1"/>
				</g>
			</svg>
		</a>
		<div class="setting-main">
			<object  v-html="svgs_data.setting_icon" @click="showSettings()"></object>
			<div v-if="navbarStore.settings_dropdown" class="setting-dropdown-main is-ghana active">
				<setting-dropdown v-if="navbarStore.settings_dropdown"></setting-dropdown>
			</div>
		</div>
		</div>
		<!-- <div class="header-btn"> -->
			<a @click="showLoginModal()" class="btn-small">{{ $t('general.login') }}</a>
			<a @click="showRegisterModal()" class="btn-small btn-secondary">{{ $t('general.register') }}</a>
		<!-- </div> -->
	</div>
	<template v-else >
		<div class="header-icons">

			<!-- <a class="notifications-main" id="notifications-dropdown">
				<object v-html="commonSvgs.gift_gray_ico"></object>
				<span  class="notification-num">1</span>
			</a> -->

			<!-- <a @click="showRegisterModal()">
				<svg xmlns="http://www.w3.org/2000/svg" width="25.999" height="24" viewBox="0 0 25.999 24">
					<g id="Group_1852" data-name="Group 1852" transform="translate(-261.704 -15.5)">
						<g id="Group_1047" data-name="Group 1047" transform="translate(262.204 16)">
						<g id="g10342" transform="translate(5.034)">
							<path id="path10344" d="M0-164.7a5.966,5.966,0,0,1,5.966-5.966,5.966,5.966,0,0,1,5.966,5.966,5.966,5.966,0,0,1-5.966,5.966A5.966,5.966,0,0,1,0-164.7Z" transform="translate(0 170.666)" fill="none" stroke="#f5bd42" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" stroke-width="1"/>
						</g>
						<g id="g10346" transform="translate(0 11.932)">
							<path id="path10348" d="M-491.654-37.315a10.281,10.281,0,0,1,2.614,1.633,4.65,4.65,0,0,1,1.576,3.494v1.725a1.864,1.864,0,0,1-1.864,1.864H-507.6a1.864,1.864,0,0,1-1.864-1.864v-1.725a4.649,4.649,0,0,1,1.576-3.494c1.7-1.494,4.8-2.985,9.424-2.985" transform="translate(509.463 38.667)" fill="none" stroke="#f5bd42" stroke-linecap="round" stroke-miterlimit="10" stroke-width="1"/>
						</g>
						</g>
						<g id="Group_1048" data-name="Group 1048" transform="translate(282.203 16.486)">
						<line id="Line_72" data-name="Line 72" y1="5" transform="translate(2.5)" fill="none" stroke="#f5bd42" stroke-linecap="round" stroke-width="1"/>
						<line id="Line_73" data-name="Line 73" y1="5" transform="translate(5 2.5) rotate(90)" fill="none" stroke="#f5bd42" stroke-linecap="round" stroke-width="1"/>
						</g>
					</g>
					</svg>
			</a> -->

			<div class="setting-main" id="settings-dropdown">
				<object  v-html="svgs_data.setting_icon" @click="showSettings()"></object>
				<div v-if="navbarStore.settings_dropdown" class="setting-dropdown-main active">
					<setting-dropdown v-if="navbarStore.settings_dropdown"></setting-dropdown>
				</div>
			</div>
			<a @click="showSportsbookSearchModal($event)">
				<svg xmlns="http://www.w3.org/2000/svg" id="Icon_search" data-name="Icon search" width="22.503" height="22.503" viewBox="0 0 22.503 22.503">
					<g id="Path_3" data-name="Path 3" transform="translate(-2.25 -2.25)" fill="none" stroke-linecap="round" stroke-linejoin="round">
						<path d="M22.546,12.4A10.148,10.148,0,1,1,12.4,2.25,10.148,10.148,0,0,1,22.546,12.4Z" stroke="none"/>
						<path d="M 12.39806365966797 21.54611968994141 C 17.44232368469238 21.54611968994141 21.54612350463867 17.44231986999512 21.54612350463867 12.39806938171387 C 21.54612350463867 7.353809833526611 17.44232368469238 3.249999761581421 12.39806365966797 3.249999761581421 C 7.353803634643555 3.249999761581421 3.250003576278687 7.353799819946289 3.250003576278687 12.3980598449707 C 3.250003576278687 17.44231986999512 7.353803634643555 21.54611968994141 12.39806365966797 21.54611968994141 M 12.39806365966797 22.54611968994141 C 6.79344367980957 22.54611968994141 2.250003576278687 18.0026798248291 2.250003576278687 12.3980598449707 C 2.250003576278687 6.793439865112305 6.79344367980957 2.249999761581421 12.39806365966797 2.249999761581421 C 18.00268363952637 2.249999761581421 22.54612350463867 6.793449878692627 22.54612350463867 12.39806938171387 C 22.54612350463867 18.0026798248291 18.00268363952637 22.54611968994141 12.39806365966797 22.54611968994141 Z M 22.54612350463867 12.39806938171387 C 22.54612350463867 12.3980598449707 22.54612350463867 12.3980598449707 22.54612350463867 12.3980598449707 L 22.54612350463867 12.39806938171387 Z" stroke="none" fill="#888a8f"/>
					</g>
					<path id="Path_4" data-name="Path 4" d="M15.75,15.75l-3.262-3.262" transform="translate(6.046 6.046)" fill="none" stroke="#888a8f" stroke-linecap="round" stroke-linejoin="round" stroke-width="1"/>
				</svg>
			</a>

			<a @click="showLoginModal()">
				<svg xmlns="http://www.w3.org/2000/svg" width="25.207" height="25.207" viewBox="0 0 18.999 19">
					<g id="Group_1046" data-name="Group 1046" transform="translate(-322.693 -15.5)">
					<g id="g10342" transform="translate(327.312 16)">
						<path id="path10344" d="M0-165.785a4.881,4.881,0,0,1,4.881-4.881,4.881,4.881,0,0,1,4.881,4.881A4.881,4.881,0,0,1,4.881-160.9,4.881,4.881,0,0,1,0-165.785Z" transform="translate(0 170.666)" fill="none" stroke="#8e9298" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" stroke-width="1"/>
					</g>
					<g id="g10346" transform="translate(323.193 25.763)">
						<path id="path10348" d="M-494.892-37.561a8.411,8.411,0,0,1,2.139,1.336,3.8,3.8,0,0,1,1.289,2.859v1.411a1.525,1.525,0,0,1-1.525,1.525h-14.948a1.525,1.525,0,0,1-1.525-1.525v-1.411a3.8,3.8,0,0,1,1.289-2.859,11.568,11.568,0,0,1,7.71-2.442" transform="translate(509.463 38.667)" fill="none" stroke="#8e9298" stroke-linecap="round" stroke-miterlimit="10" stroke-width="1"/>
					</g>
					</g>
				</svg>
			</a>
		</div>

		<a class="btn-small btn-secondary" @click="showRegisterModal()">{{ t('account.register.register') }}</a>
	</template>

</template>
<style scoped>
.row-container {
	display: flex;
	align-items: center;
}

.for-ghana {
	margin-right: 10px;
}

.header-btn {
	margin-left: auto;
}
@media screen and (min-width: 320px) and (max-width: 767px) {
	.right-content .header-icons .setting-main .is-ghana {
		padding: 15px;
		right: -133px;
	}
	.btn-small {
		font-size: 11px;
		font-weight: 500;
		width: 50%;
	}
	a.btn-small.btn-secondary {
		margin-left: 10px;
	}
	html[dir="rtl"] a.btn-small.btn-secondary {
		margin-left: unset;
		margin-right: 10px;
	}
	.right-content .for-ghana a:first-child svg {
		width: 17px;
		top: unset;
	}

	html[dir="rtl"] .right-content .header-icons .setting-main .is-ghana {
		right: unset;
		left: -128px;
	}
}

html[dir="rtl"] .for-ghana {
	margin-right: unset;
	margin-left: 10px;
}
</style>