<script setup>
/* eslint-disable */
import { onMounted, computed, ref } from 'vue';
import { useRouter } from 'vue-router';
import { useMarketsStore } from '@/stores/markets';
import { useFixtureStore } from '@/stores/fixture';
import RacingRightSidebarFixtureOdd from './RacingRightSidebarFixtureOdd.vue';
import _ from 'lodash';
import moment from 'moment';

const router = useRouter();

const fixtureStore = useFixtureStore();
const marketsStore = useMarketsStore();

const primary_overview_market = ref(marketsStore.sportOverviewMarkets(props.fixture.sport_id)[0]);

const props = defineProps({
	fixture: {
		type: Object,
		required: true,
	},
});

onMounted(() => {
	primary_overview_market.value = marketsStore.sportOverviewMarkets(props.fixture.sport_id)[0];
});

const goToFixture = () => {
	router.push({ name: 'racing-fixture-book', params: { sport_id: props.fixture.sport_id, region_id: props.fixture.region_id, competition_id: props.fixture.competition_id, fixture_id: props.fixture.id } } )
}

const primaryOverviewMarketOdds = computed(() => {
	if (!props.fixture.overview_markets.hasOwnProperty(primary_overview_market.value.slug)) return [];

	return _.chain(props.fixture.overview_markets[primary_overview_market.value.slug]).orderBy('sort').take(3).value();
})

</script>
<template>
	<div class="sidebar-items">
		<div @click="goToFixture" class="upcoming-race-header">
			<div class="upcoming-race-inner">
				<div class="country-detail">
					<!-- <div class="country-flag">
						<img style="width: 20px;" :src="'/assets' + fixture.region_icon_url" alt="">
					</div> -->
				   <div class="country-name">
						<p>{{ fixture.competition_region_name }}</p>
					</div>
				</div>
				<div class="race-countdown">
					<span>
						<svg xmlns="http://www.w3.org/2000/svg" width="11" height="11" viewBox="0 0 11 11"><g id="clock" transform="translate(0.5 0.5)"><g id="Icon_feather-clock" data-name="Icon feather-clock" transform="translate(-1 -1)"><path id="Path_907" data-name="Path 907" d="M11,6A5,5,0,1,1,6,1a5,5,0,0,1,5,5Z" fill="none" stroke="#ffbb02" stroke-linecap="round" stroke-linejoin="round" stroke-width="1"></path><path id="Path_908" data-name="Path 908" d="M6,3V6L8,7" fill="none" stroke="#ffbb02" stroke-linecap="round" stroke-linejoin="round" stroke-width="1"></path></g></g>
						</svg>
					</span>
					<p>{{ $t('racing.starts_in') }} {{ moment(fixture.start_datetime).diff(moment(), 'minutes') }} {{ $t('racing.minutes_short') }}</p>
				</div>
			</div>
			<div class="upcoming-extra-info">
				<div class="upcomimg-extra-inner">
					<p>{{ fixture.venue_title }}</p>
				</div>
				<!-- <div class="upcomimg-extra-inner">
					<p>E/W 1/5 Odds 3 Places</p>
				</div> -->
			</div>
		</div>

		<RacingRightSidebarFixtureOdd v-for="odd in primaryOverviewMarketOdds" :key="odd.id"
			:odd="odd"
			:fixture="props.fixture"
		/>
	</div>
</template>
<style scoped>
.upcoming-race-header{
	cursor: pointer;
}
</style>