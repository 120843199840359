<script setup>
/* eslint-disable */
import { ref, onMounted, watch } from "vue";
import moment from 'moment';

import { useRouter } from 'vue-router';
import { useRacingSportsStore } from '@/stores/racing_sports';
import { useDatesStore } from '@/stores/dates';
import { useCustomerStore } from '@/stores/customer';
import { useTimezonesStore } from '@/stores/timezones';
import { useGeneralStore } from '@/stores/general';
import { useI18n } from 'vue-i18n';

import LoaderComponent from '@/components/Common/LoaderComponent.vue';

const router = useRouter();
const racingSportsStore = useRacingSportsStore();
const datesStore = useDatesStore();
const customerStore = useCustomerStore();
const timezonesStore = useTimezonesStore();
const { t } = useI18n();

const collapsed = ref(false);
const active_tab = ref('today');
const loading = ref(false);

onMounted(() => {
	if (racingSportsStore.selectedRacingSportId) {
		getTodayFixtures();
		setPageTitle();
	}
})

const changeActiveTab = (tab) => {
	active_tab.value = tab;
}

const getTodayFixtures = () => {
	changeActiveTab('today');

	moment.locale('en');
	let date = datesStore.translateAndOffsetDatetime(moment());
	let payload = {
		sport_id: racingSportsStore.selectedRacingSportId,
		date_range: date.format('YYYY-MM-DD') + ' - ' + date.format('YYYY-MM-DD')
	}

	loading.value = true;
	racingSportsStore.fetchCoupons(payload).then(response => {
		loading.value = false;
	}).catch(error => {
		loading.value = false;
	});

}

const getTomorrowFixtures = () => {
	changeActiveTab('tomorrow');

	moment.locale('en');
	let date = datesStore.translateAndOffsetDatetime(moment().add(1, 'days'));
	let payload = {
		sport_id: racingSportsStore.selectedRacingSportId,
		date_range: date.format('YYYY-MM-DD') + ' - ' + date.format('YYYY-MM-DD')
	}

	loading.value = true;
	racingSportsStore.fetchCoupons(payload).then(response => {
		loading.value = false;
	}).catch(error => {
		loading.value = false;
	});


}

const get2DaysFixtures = () => {
	changeActiveTab('in_2_days');

	moment.locale('en');
	let date = datesStore.translateAndOffsetDatetime(moment().add(2, 'days'));
	let payload = {
		sport_id: racingSportsStore.selectedRacingSportId,
		date_range: date.format('YYYY-MM-DD') + ' - ' + date.format('YYYY-MM-DD')
	}

	loading.value = true;
	racingSportsStore.fetchCoupons(payload).then(response => {
		loading.value = false;
	}).catch(error => {
		loading.value = false;
	});

}

const get3DaysFixtures = () => {
	changeActiveTab('in_3_days');

	moment.locale('en');
	let date = datesStore.translateAndOffsetDatetime(moment().add(3, 'days'));
	let date_to = datesStore.translateAndOffsetDatetime(moment().add(1, 'month'));

	let payload = {
		sport_id: racingSportsStore.selectedRacingSportId,
		date_range: date.format('YYYY-MM-DD') + ' - ' + date_to.format('YYYY-MM-DD')
	}

	loading.value = true;
	racingSportsStore.fetchCoupons(payload).then(response => {
		loading.value = false;
	}).catch(error => {
		loading.value = false;
	});
}

const getFirstFixture = (region) => {
	let keys = Object.keys(region);
	let first_competition = region[keys[0]];
	return first_competition[0];
}

const goToFixture = (fixture) => {
	router.push({ name: 'racing-fixture-book', params: { sport_id: fixture.sport_id, region_id: fixture.region_id, competition_id: fixture.competition_id, fixture_id: fixture.id } } )
}

const setPageTitle = () => {
	document.title = useGeneralStore().getGeneral.site_name + ' - ' + racingSportsStore.selectedSport.name + ' ' + t('general.coupons');
}

watch(() => racingSportsStore.selectedSport, (newValue, oldValue) => {
	getTodayFixtures();

	setPageTitle();
})


</script>
<template>
	<div class="racing-odds-main" :class="{ 'collapsed': collapsed }">
		<div class="racing-outer-odds">
			<div class="title">
				<h6>{{ racingSportsStore.selectedSport ? racingSportsStore.selectedSport.name : ''}} {{ $t('racing.betting_odds') }}</h6>
				<span class="collapse-ico" @click="collapsed = !collapsed">
					<svg xmlns="http://www.w3.org/2000/svg" width="9.414" height="5.414" viewBox="0 0 9.414 5.414">
					<g id="Group_232" data-name="Group 232" transform="translate(8.707 0.707) rotate(90)">
						<line id="Line_7" data-name="Line 7" x1="4" y2="4" fill="none" stroke="#dbe1e6" stroke-linecap="round" stroke-width="1"/>
						<line id="Line_8" data-name="Line 8" x1="4" y1="4" transform="translate(0 4)" fill="none" stroke="#dbe1e6" stroke-linecap="round" stroke-width="1"/>
					</g>
					</svg>
				</span>
			</div>

			<div class="racing-tab_area">
				<div class="racing-odds-tabs">
					<div class="tab desktop-tab-left">
						<button class="tablinks" :class="{'active': active_tab == 'today'}" @click="getTodayFixtures">Today</button>
						<button class="tablinks" :class="{'active': active_tab == 'tomorrow'}" @click="getTomorrowFixtures">Tomorrow</button>
						<button class="tablinks" :class="{'active': active_tab == 'in_2_days'}" @click="get2DaysFixtures">{{ moment().add(2, 'days').format('dddd') }}</button>
						<button class="tablinks" :class="{'active': active_tab == 'in_3_days'}" @click="get3DaysFixtures">Future Races</button>
					</div>
				</div>

				<template v-if="loading">
					<LoaderComponent></LoaderComponent>
				</template>
				<template v-else>
					<template v-if="Object.keys(racingSportsStore.couponsFixtures).length == 0">
						<div>
							<p class="no-events">{{ $t("racing.no_racing_events_available") }}</p>
						</div>
					</template>
					<template v-else>
						<div v-for="(region, region_name) in racingSportsStore.couponsFixtures" :key="region_name" class="tab-content racing-tab-content">
							<div class="racing-tab-inner">
								<!-- tab content section start-->
								<div class="racing-tab-items">
									<div class="country-detail">
										<!-- <div class="country-flag">
											<img style="width: 30px;" :src="'/assets' + getFirstFixture(region).region_icon_url" alt="">
										</div> -->
										<div class="country-name">
											<p>{{ region_name }}</p>
										</div>
									</div>
									<!-- odd area start -->
									<div v-for="(competition, competition_name) in region" :key="competition_name" class="inner-odds-selection">
										<div class="inner-odds-left">
											<div class="odds-info-content">
												<!-- <div class="star-icon">
													<svg xmlns="http://www.w3.org/2000/svg" width="12" height="11.143" viewBox="0 0 12 11.143"><path id="Icon_ionic-ios-star" class="fixture-460957" data-name="Icon ionic-ios-star" d="M12.67,5.545H8.729l-1.2-3.573a.434.434,0,0,0-.814,0l-1.2,3.573H1.554a.43.43,0,0,0-.429.429.315.315,0,0,0,.008.072.412.412,0,0,0,.179.3L4.551,8.63,3.308,12.244a.43.43,0,0,0,.147.482.414.414,0,0,0,.241.1.525.525,0,0,0,.268-.1l3.161-2.253,3.161,2.253a.5.5,0,0,0,.268.1.385.385,0,0,0,.238-.1.425.425,0,0,0,.147-.482L9.7,8.63l3.212-2.3.078-.067a.449.449,0,0,0,.139-.287A.454.454,0,0,0,12.67,5.545Z" transform="translate(-1.125 -1.688)" fill="#4E545D"></path></svg>
												</div> -->
												<p>{{ competition_name }}</p>
											</div>
										</div>
										<div class="inner-odds-right">
											<div class="odds-column">
												<div v-for="fixture in competition" :key="fixture.id" class="odds-items" @click="goToFixture(fixture)">
													<p>{{ datesStore.preFixtureBookTime(fixture.start_datetime) }}</p>
												</div>
											</div>
										</div>
									</div>
									<!-- odd area end -->

								</div>
								<!-- tab content section end-->
							</div>
						</div>
					</template>
				</template>
			</div>
		</div>
	</div>
</template>
<style scoped>
.racing-tab-inner {
	padding-top: 10px;
}
.no-events {
	text-align: center;
	color: #ffff;
}
</style>
