<script setup>
/* eslint-disable */
import LiveFixtureDoubleMarket from '@/components/live_sports/LiveFixtureDoubleMarket.vue';
import commonSvgs from '@/assets/svgs/commonSvgs.json'
import { onMounted, ref, watch } from 'vue';

const svg_data = ref(commonSvgs);

const competition_closed = ref(false);
const props = defineProps({
	competition: {
		type: Object,
		required: true,
	},
	competitions_closed: {
		type: Boolean,
		required: true,
	},
	primary_overview_market: {
		type: Object,
		required: true,
	},
	secondary_overview_market: {
		type: Object,
		required: true,
	},
});

const is_hidden = ref(false);

const visibilityChanged = (isVisible, entry) => {
	is_hidden.value = !isVisible;
}

onMounted(() => {
	competition_closed.value = props.competitions_closed;
});

watch(() => props.competitions_closed, (newValue, oldValue) => {
	competition_closed.value = newValue;
})

</script>
<template>
	<div v-observe-visibility="visibilityChanged" :style="is_hidden ? 'visibility:hidden;' : ''" class="live-info-outer">
		<div class="live-info-inner">
			<div class="live-info-team">

				<div class="live-match">
					<!-- <div class="team-flag">
						<img :src="'/assets' + competition[0].region_icon_url" alt="">
					</div> -->
					<div class="live-match-name">
						<h3>{{ competition[0].region_name }} - {{ competition[0].competition_name }} </h3>
					</div>
				</div>
			</div>
			<div @click="competition_closed = !competition_closed" class="market-arrow-mobile">
				<div></div>
				<a class="market-arrow-icon" :class="competition_closed ? 'market-arrow-icon-closed' : ''"></a>
			</div>

			<div class="full-time-top top-title-dash">
				<template v-for="(odd, id) in primary_overview_market?.odd_names" :key="id">
					<span v-if="odd != ''">{{ odd }}</span>
				</template>
			</div>

			<div class="match-goal-top top-title-dash">
				<template v-for="(odd, id) in secondary_overview_market?.odd_names" :key="id">
					<span v-if="odd != ''">{{ odd }}</span>
				</template>
				<span class="bet-column-empty">
					<div @click="competition_closed = !competition_closed" class="market-arrow">
						<a class="market-arrow-icon" :class="competition_closed ? 'market-arrow-icon-closed' : ''"></a>
					</div>
				</span>

			</div>
		</div>
		<template v-if="!competition_closed">
			<LiveFixtureDoubleMarket v-for="fixture in competition"
				:key="fixture.id"
				:primary_overview_market="primary_overview_market"
				:secondary_overview_market="secondary_overview_market"
				:fixture="fixture"
			/>
		</template>
	</div>
</template>
<style scoped>


.dropdown-option-top {
	display: block !important;
}

@media screen and (min-width: 320px) and (max-width: 1600px) {
	.dropdown-option-top {
		display: block !important;
	}

	.dropdown-option-top-2 {
		display: none !important;
	}

	.live-bet-dashboard .live-tab-info .live-info-outer .live-info-inner .match-goal-top {
		display: none;
	}

}

.live-info-outer {
	/* Force hardware acceleration */
	transform: translate3d(0, 0, 0);
	-webkit-transform: translate3d(0, 0, 0);
}

@media screen and (min-width: 1191px) and (max-width: 1600px) {
	.sports-main .live_Sports-main-outer .live-sports-center .live-bet-dashboard.round-game-liveSport .live-tab-info .live-info-outer .live-info-inner:first-child {
		grid-template-columns: 1fr 1fr;
	}
}
</style>
