<script setup>
import { onMounted, onUnmounted, ref } from "vue";
import { useSocketStore } from '@/stores/socket';
import { useLiveHomeFixturesStore } from '@/stores/live_home_fixtures';

import { Splide, SplideSlide } from '@splidejs/vue-splide';
import LiveFixture from './live_now/LiveFixture.vue';

const direction = ref(document.dir);
const options = ref({
	direction: direction,
	rewind: false,
	pagination: false,
	type: 'slide',
	perMove: 1,
	perPage: 3,
	gap: '2rem',
	autoWidth: false,
	breakpoints: {
		767: {
			perPage: 1,
			gap: '0px',
			perMove: 1,
			autoWidth: true,
			type:'loop'
		},
		768: {
			perPage: 2,
			gap: '1rem',
			perMove: 1,
		},
		992:   {
			perPage: 2,
			gap: '1rem',
			perMove: 1,
		},
		1024: {
			perPage: 2,
			perMove: 1,
			gap: '1rem',
		},
		1200: {
			perPage: 2,
			perMove: 1,
			gap: '3rem',
		},
		1281: {
			perPage: 3,
			gap: '1rem',
			perMove: 1,
		},
		1400: {
			gap: '1rem',
		},
		1440: {
			perPage: 3,
			gap: '1.5rem',
			perMove: 1,
		},
		1680: {
			perPage: 3,
			gap: '1.5rem',
			perMove: 1,
		},
		1920: {
			perPage: 3,
			gap: '2rem',
			perMove: 1,
		},
	},
});


const socketStore = useSocketStore();
const liveHomeFixturesStore = useLiveHomeFixturesStore();

socketStore.getSocket.on('liveHomeFixtures', (data) => {
	liveHomeFixturesStore.setLiveHomeFixturesSnapshot(data);
})

socketStore.getSocket.on('liveHomeFixturesUpdate', (data) => {
	liveHomeFixturesStore.updateLiveHomeFixtures(data);
});

onMounted(() => {
	socketStore.getSocket.emit('join-liveHomeFixtures');
})

onUnmounted(() => {
	socketStore.getSocket.emit('leave-liveHomeFixtures');
})

</script>
<template>
	<div class="live-score-container">
		<Splide :options="options">
			<SplideSlide v-for="fixture in liveHomeFixturesStore.liveHomeFixtures" :key="fixture.id">
				<LiveFixture :fixture="fixture"/>
			</SplideSlide>
		</Splide>
	</div>
</template>
<style scoped>
.live-score-main .live-score-container :deep(.splide__arrow:disabled) {
	display: none;
}
html[dir="rtl"] .live-score-main .live-score-container :deep(.splide .splide__arrow--prev) {
	rotate: 180deg;
	right: -5%;
	left: unset !important;
}

html[dir="rtl"] .live-score-main .live-score-container :deep(.splide .splide__arrow--next) {
	rotate: 180deg;
	left: -5%;
	right: unset !important;
}

@media screen and (min-width: 768px) and (max-width: 991px) {
	html[dir="rtl"] .live-score-main .live-score-container :deep(.splide .splide__arrow--prev) {
		top: 110%;
		height: 24px;
		width: 24px;
		left: 52% !important;
	}

	html[dir="rtl"] .live-score-main .live-score-container :deep(.splide .splide__arrow--next) {
		right: 52% !important;
		top: 110%;
		height: 24px;
		width: 24px;
	}
}

@media screen and (min-width: 992px) and (max-width: 1190px) {
	html[dir="rtl"] .live-score-main .live-score-container :deep(.splide .splide__arrow--prev) {
		rotate: 180deg;
		right: -35px !important;
		left: unset;
	}

	html[dir="rtl"] .live-score-main .live-score-container :deep(.splide .splide__arrow--next) {
		rotate: 180deg;
		left: -35px !important;
		right: unset !important;
	}
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
	.live-score-pannel:before {
		width: 50%;
		margin-left: 25%;
	}
}
</style>