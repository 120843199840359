<!-- eslint-disable -->
<script setup>
import { useFooterSectionStore } from '@/stores/footer_sections';
import { useInitializeStore } from '@/stores/initialize';
import moment from 'moment';

const footerSectionStore = useFooterSectionStore();
const initializeStore = useInitializeStore();

</script>
<template>
	<template v-if="footerSectionStore.getRegulationSection && footerSectionStore.getRegulationSection.items.length > 0">
		<div class="regulation-flex" >
			<a v-for="item in footerSectionStore.getRegulationSection.items" :key="item.id" :href="item.redirect_url" target="_blank">
				<img :src="item.media_url" alt="Regulation Icon" width="30">
			</a>
			<span id="browser-time" class="browser-time"> {{ moment.unix(initializeStore.server_timestamp).utc().format('hh:mm:ss A') }} UTC</span>
		</div>
	</template>
</template>

<style scoped>
.regulation-flex {
	display: flex;
	gap: 10px;
	/* max-width: 50%; */
	align-items: center;
	flex-wrap: wrap;
}

.browser-time {
	width: 150px;
	background-color: gray;
	color: white;
	padding: 5px 10px;
	border-radius: 5px;
	/* font-weight: bold; */
	display: inline-block;
	height:100%
}

@media screen and (max-width: 767px) {
	.browser-time {
		display: none;
	}
}
</style>
