<script setup>
/* eslint-disable */
import { onMounted, onBeforeUnmount, watch, ref } from "vue";
import { useRouter } from 'vue-router';
import { useSocketStore } from '@/stores/socket';
import { useMarketsStore } from '@/stores/markets';
import { useLiveSportsStore } from '@/stores/live_sports';
import { useLiveSportOverview } from '@/stores/live_sport_overview';
import { useVirtualCourtStore } from '@/stores/virtual_court';
import { useGeneralStore } from '@/stores/general';
import { useFavouritesStore } from '@/stores/favourites';
import { useI18n } from 'vue-i18n';
import LiveFixtureDoubleMarket from '@/components/live_sports/LiveFixtureDoubleMarket.vue';
import LiveSportOverviewCompetition from '@/components/live_sports/LiveSportOverviewCompetition.vue';
import LiveSportOverviewFavourites from '@/components/live_sports/LiveSportOverviewFavourites.vue';

import commonSvgs from '@/assets/svgs/commonSvgs.json';
import { SportsData } from '@/stores/Sports';

const { t } = useI18n();
const svg_data = ref(commonSvgs);

const router = useRouter();
const socketStore = useSocketStore();
const marketsStore = useMarketsStore();
const liveSportsStore = useLiveSportsStore();
const liveSportOverviewStore = useLiveSportOverview();
const virtualCourtStore = useVirtualCourtStore();
const generalStore = useGeneralStore();
const favouritesStore = useFavouritesStore();
const SportsDataStore = SportsData();

const show_primary_overview_market = ref(false);
const show_secondary_overview_market = ref(false);

const primary_overview_market = ref(null);
const secondary_overview_market = ref(null);

const competitions_closed = ref(false);

const show_scroll_to_top_button = ref(false);

socketStore.getSocket.on('liveSportOverview', (data) => {
	liveSportOverviewStore.setOverview(data);

	if (router.currentRoute.value.name != 'live-sports-overview') return;

	// Set first fixture of overview to diplay it in virtual live
	// virtualCourtStore.setFixtureInQueue(liveSportOverviewStore.getFirstFixture);
})

socketStore.getSocket.on('liveSportOverviewUpdate', (data) => {
	liveSportOverviewStore.updateOverview(data);
})
const scrollToTop = () => {
	const container = document.querySelector('.live-tab-info');
	window.scrollTo({ top: 0 });

	if (container) {
		container.scrollTo({ top: 0 });
	}
};

const handleScroll = () => {
	const scrollTop = window.scrollY;
	show_scroll_to_top_button.value = scrollTop > 100;
};

const handleElementScroll = () => {
	const container = document.querySelector('.live-tab-info');

	if (container) {
		const scrollTop = container.scrollTop;
		show_scroll_to_top_button.value = scrollTop > 100;
	}
};

const selectPrimaryOverviewMarket = (overview_market) => {
	primary_overview_market.value = overview_market;
	show_primary_overview_market.value = false;
}

const selectSecondaryOverviewMarket = (overview_market) => {
	secondary_overview_market.value = overview_market;
	show_secondary_overview_market.value = false;
}

const closePrimaryOverviewMarketSelector = () => {
	show_primary_overview_market.value = false;
}

const closeSecondaryOverviewMarketSelector = () => {
	show_secondary_overview_market.value = false;
}

onMounted(() => {
	if (liveSportsStore.selected_live_sport_id) {
		socketStore.getSocket.emit('join-liveSportOverview', liveSportsStore.selected_live_sport_id);
		let sport_overview_markets = marketsStore.sportOverviewMarkets(liveSportsStore.selected_live_sport_id);
		if (sport_overview_markets.length > 1) {
			primary_overview_market.value = sport_overview_markets[0];

			secondary_overview_market.value = sport_overview_markets[1]
		} else {
			primary_overview_market.value = sport_overview_markets[0];
			secondary_overview_market.value = sport_overview_markets[0]
		}
	}

	window.addEventListener('scroll', handleScroll);
	const container = document.querySelector('.live-tab-info');

	if (container) {
		container.addEventListener('scroll', handleElementScroll);
	}

	document.title = generalStore.getGeneral.site_name + ' - ' + t('general.live_sports');
});

onBeforeUnmount(() => {
	socketStore.getSocket.emit('leave-liveSportOverview');
	liveSportOverviewStore.emptyLiveSportsOverview();
})

watch(() => liveSportsStore.selected_live_sport_id, (newSportId, oldSportId) => {
	if (newSportId > 0) {
		liveSportOverviewStore.emptyLiveSportsOverview();

		// Find first overview market of new selected live sport id
		socketStore.getSocket.emit('join-liveSportOverview', newSportId);

		let sport_overview_markets = marketsStore.sportOverviewMarkets(newSportId);
		if (sport_overview_markets.length > 1) {
			primary_overview_market.value = sport_overview_markets[0];

			secondary_overview_market.value = sport_overview_markets[1]
		} else {
			primary_overview_market.value = sport_overview_markets[0];
			secondary_overview_market.value = sport_overview_markets[0]
		}

	} else if (newSportId == null) {
		socketStore.getSocket.emit('leave-liveSportOverview');
		liveSportOverviewStore.emptyLiveSportsOverview();
	}
})

// If no more favourites selected select 1st sport of live sports
watch(() => favouritesStore.liveSportOverviewFavourites, (newValue, oldvalue) => {
	if (!liveSportsStore.favouritesSelected) return;

	if (Object.keys(newValue) == 0) {
		liveSportsStore.selectFavourites(false);
		liveSportsStore.setSelectedLiveSportId(liveSportsStore.liveSports[0].id);
	}
})

watch(() => liveSportOverviewStore.overviewFixturesKeys, (newValue, oldvalue) => {
	if (newValue == null) return;

	if (!newValue.includes(String(virtualCourtStore.selectedFixtureId))) {
		virtualCourtStore.setFixtureInQueue(liveSportOverviewStore.getFirstFixture)
	}
})
</script>
<template>
	<template v-if="!liveSportsStore.favouritesSelected">
		<div class="live-bet-dashboard round-game-liveSport round-games-main " :class="!SportsDataStore.sport_has_three_scores(liveSportsStore.selected_live_sport_id)&&!SportsDataStore.sport_has_two_scores(liveSportsStore.selected_live_sport_id)?'round-game-outer':'set_game-outer'">
			<div class="bet-dashboard-top">
				<div class="dashboard-info-label bet-tab-title">
					<div class="sport-title">
						<h6>{{ liveSportsStore.getSelectedLiveSportName }} {{ liveSportOverviewStore.overviewFixturesCount ? '(' +  liveSportOverviewStore.overviewFixturesCount + ')' : '' }}</h6>
					</div>

					<div class="live-badge" v-html="svg_data.live_badge"></div>
				</div>
				<!-- <div class="mobile-info-label">
					<img style="max-width:20px;" :src="liveSportsStore.liveSportGreenIcon(liveSportsStore.selected_live_sport_id)" alt="">
					<h3>{{ liveSportsStore.getSelectedLiveSportName }} {{ liveSportOverviewStore.overviewFixturesCount ? '(' +  liveSportOverviewStore.overviewFixturesCount + ')' : '' }}</h3>
				</div> -->

				<div class="dashboard-info-label dropdown-option-top" v-if="primary_overview_market">
					<div class="select-option">
						<dl class="drop" :class="show_primary_overview_market ? 'active' : ''">
							<span class="tabIco">
								<svg xmlns="http://www.w3.org/2000/svg" width="4" height="3" viewBox="0 0 4 3">
									<g id="Group_73" data-name="Group 73" transform="translate(-134 -568)">
										<path id="Polygon_2" data-name="Polygon 2" d="M2,0,4,3H0Z" transform="translate(138 571) rotate(180)" fill="#fff" />
									</g>
								</svg>
							</span>
							<dt><button class="tablinks" @click="show_primary_overview_market = !show_primary_overview_market"  v-click-outside-element="closePrimaryOverviewMarketSelector">{{ primary_overview_market.name.toUpperCase() }}</button>
							</dt>
							<dd>
								<ul>
									<li v-for="overview_market in marketsStore.sportOverviewMarkets(liveSportsStore.selected_live_sport_id)" :key="overview_market.slug" @click="selectPrimaryOverviewMarket(overview_market)" :class="primary_overview_market.slug == overview_market.slug ? 'active' : ''">
										<button class="tablinks">{{ overview_market.name.toUpperCase() }}</button>
									</li>
								</ul>
							</dd>
						</dl>
					</div>
				</div>

				<div class="dashboard-info-label dropdown-option-top dropdown-option-top-2" v-if="secondary_overview_market">
					<div class="select-option">
						<dl class="drop" :class="show_secondary_overview_market ? 'active' : ''">
							<span class="tabIco">
								<svg xmlns="http://www.w3.org/2000/svg" width="4" height="3" viewBox="0 0 4 3">
									<g id="Group_73" data-name="Group 73" transform="translate(-134 -568)">
										<path id="Polygon_2" data-name="Polygon 2" d="M2,0,4,3H0Z" transform="translate(138 571) rotate(180)" fill="#fff" />
									</g>
								</svg>
							</span>
							<dt><button class="tablinks" @click="show_secondary_overview_market = !show_secondary_overview_market"  v-click-outside-element="closeSecondaryOverviewMarketSelector">{{ secondary_overview_market.name.toUpperCase() }}</button>
							</dt>
							<dd>
								<ul>
									<li v-for="overview_market in marketsStore.sportOverviewMarkets(liveSportsStore.selected_live_sport_id)" :key="overview_market.slug" @click="selectSecondaryOverviewMarket(overview_market)" :class="secondary_overview_market.slug == overview_market.slug ? 'active' : ''">
										<button class="tablinks">{{ overview_market.name.toUpperCase() }}</button>
									</li>
								</ul>
							</dd>
						</dl>
					</div>
				</div>

				<span class="toggle-markets">
					<div @click="competitions_closed = !competitions_closed" class="markets-toggle-fill">
						<svg v-if="!competitions_closed" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" svg-inline="" title="Toggle" role="presentation" focusable="false" xmlns:xlink="http://www.w3.org/1999/xlink" class="markets-toggle-svg">
							<use xlink:href="/assets/icons/markets_close.svg#markets_close"></use>
						</svg>
						<svg v-else xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" svg-inline="" title="Toggle" role="presentation" focusable="false" xmlns:xlink="http://www.w3.org/1999/xlink" class="markets-toggle-svg">
							<use xlink:href="/assets/icons/markets_open.svg#markets_open"></use>
						</svg>
					</div>
				</span>

			</div>
			<div class="live-tab-info">
				<div id="overview" class=" overview-live">
					<LiveSportOverviewCompetition v-for="(competition, index) in liveSportOverviewStore.overviewFixtures" :key="index"
						:competition="competition"
						:competitions_closed="competitions_closed"
						:primary_overview_market="primary_overview_market"
						:secondary_overview_market="secondary_overview_market"
					/>
				</div>
			</div>
		</div>
	</template>
	<template v-else>
		<LiveSportOverviewFavourites v-for="(sport, index) in favouritesStore.liveSportOverviewFavourites" :key="index" :sport_overview="sport"/>
	</template>
	<button @click="scrollToTop" type="button" class="btn scroll-to-top" id="btn-back-to-top" v-show="show_scroll_to_top_button">
		<i class="fas fa-arrow-up"></i>
	</button>
</template>

<style scoped>
@media screen and (min-width: 320px) and (max-width: 991px) {
	.live-tab-info{
		max-height: unset!important;
		position: unset!important;
		
	}
}
@media screen and (min-width: 992px) and (max-width: 3000px) {
	button#btn-back-to-top {
		display:none;
	}
}
@media screen and (min-width: 320px) and (max-width: 991px) {
	button#btn-back-to-top {
		bottom: 70px;
		left: 20px;
	}
}
@media screen and (min-width: 320px) and (max-width: 767px) {
	svg.svg-inline--fa.fa-arrow-up.fa-w-14 {
		margin-bottom: 7px!important;
	}
	button#btn-back-to-top {
		width: 30px!important;
		height: 30px!important;
		font-size: 14px!important;
	}
}
button#btn-back-to-top {
	background: #9DC137;
	width: 38px;
	height: 38px;
	border: none;
	border-radius: 50%;
	position: fixed;
	z-index: 99;
	box-shadow: 0 2px 4px rgba(0, 0, 0, 0.5);
	font-size: 16px;
	cursor: pointer;
}
html[dir="rtl"] button#btn-back-to-top {
	left: unset;
	right:20px;
}
@media screen and (min-width: 320px) and (max-width: 991px) {
	button#btn-back-to-top {
		left: 10px;
	}
}
@media screen and (min-width: 320px) and (max-width: 991px) {
	html[dir="rtl"] button#btn-back-to-top {
		left: unset;
		right:10px;
	}
}
.sports-main .live_Sports-main-outer .live-sports-center .bet-tab-title .sport-title h6 {
	color: #ffffff;
}

.dropdown-option-top {
	display: block !important;
}

@media screen and (min-width: 320px) and (max-width: 1600px) {
	.dropdown-option-top {
		display: block !important;
	}

	.dropdown-option-top-2 {
		display: none !important;
	}

	.live-bet-dashboard .live-tab-info .live-info-outer .live-info-inner .match-goal-top {
		display: none;
	}

}

@media screen and (min-width: 320px) and (max-width: 767px) {
	.sports-main .live_Sports-main-outer .live-sports-center .live-bet-dashboard .bet-dashboard-top .mobile-info-label {
		display: grid;
		grid-template-columns: 12% 88%;
		grid-gap: 5%;
	}
}

.overview-live {
	overflow-x: hidden;
	transform: translate3d(0, 0, 0);
	-webkit-transform: translate3d(0, 0, 0);
}

.live-tab-info {
	scrollbar-width: none !important;
}
@media screen and (min-width: 1191px) and (max-width: 1600px) {
	.sports-main .live_Sports-main-outer .live-sports-center .live-bet-dashboard.round-game-liveSport .bet-dashboard-top {
		grid-template-columns: 1.9fr 1fr 1.2fr;
	}
}
</style>
