<script setup>
/* eslint-disable */
import moment from 'moment';
import { useRouter } from 'vue-router';
import { ref } from 'vue';
import { useRacingSportsStore } from '@/stores/racing_sports';
import { useDatesStore } from '@/stores/dates';
import { useFavouritesStore } from '@/stores/favourites';

const racingSportsStore = useRacingSportsStore();
const datesStore = useDatesStore();
const favouritesStore = useFavouritesStore();
const router = useRouter();

const collapsed = ref(false);

const toggleFavourite = (fixture) => {
	favouritesStore.toggleFixture(fixture.id);
}

const goToFixture = (fixture) => {
	router.push({ name: 'racing-fixture-book', params: { sport_id: fixture.sport_id, region_id: fixture.region_id, competition_id: fixture.competition_id, fixture_id: fixture.id } } )
}

</script>
<template>
	<div class="sidebar-outer" :class="{ 'collapsed': collapsed }">
		<div class="title">
			<h6>{{ $t('racing.next_off') }}</h6>
			<span class="collapse-ico" @click="collapsed = !collapsed">
				<svg xmlns="http://www.w3.org/2000/svg" width="9.414" height="5.414" viewBox="0 0 9.414 5.414">
				<g id="Group_232" data-name="Group 232" transform="translate(8.707 0.707) rotate(90)">
					<line id="Line_7" data-name="Line 7" x1="4" y2="4" fill="none" stroke="#dbe1e6" stroke-linecap="round" stroke-width="1"/>
					<line id="Line_8" data-name="Line 8" x1="4" y1="4" transform="translate(0 4)" fill="none" stroke="#dbe1e6" stroke-linecap="round" stroke-width="1"/>
				</g>
				</svg>
			</span>
		</div>
		<div class="sidebar-inner">
			<!-- element start -->
			<div v-for="fixture in racingSportsStore.next3HourFixtures" :key="fixture.id" class="sidebar-items">
				<div class="sidebar-content-outer">
					<div class="country-detail">
						<div class="star-icon" @click="toggleFavourite(fixture)">
							<svg xmlns="http://www.w3.org/2000/svg" width="12" height="11.143" viewBox="0 0 12 11.143">
								<path :style="{ fill: fixture.favourite ? '#9DC137' : '' }" id="Icon_ionic-ios-star" :class="`fixture-${fixture?.id}`" data-name="Icon ionic-ios-star" d="M12.67,5.545H8.729l-1.2-3.573a.434.434,0,0,0-.814,0l-1.2,3.573H1.554a.43.43,0,0,0-.429.429.315.315,0,0,0,.008.072.412.412,0,0,0,.179.3L4.551,8.63,3.308,12.244a.43.43,0,0,0,.147.482.414.414,0,0,0,.241.1.525.525,0,0,0,.268-.1l3.161-2.253,3.161,2.253a.5.5,0,0,0,.268.1.385.385,0,0,0,.238-.1.425.425,0,0,0,.147-.482L9.7,8.63l3.212-2.3.078-.067a.449.449,0,0,0,.139-.287A.454.454,0,0,0,12.67,5.545Z" transform="translate(-1.125 -1.688)" fill="#4e545d" />
							</svg>
						</div>
						<div @click="goToFixture(fixture)" class="country-flag">
							<img style="width: 20px;" :src="'/assets' + fixture.region_icon_url" alt="">
						</div>
						<div @click="goToFixture(fixture)" class="country-name">
							<p>{{ fixture.competition_region_name }}</p>
						</div>
					</div>
					<div class="race-info-main">
						<div class="race-info-left">
							<div class="star-icon" @click="toggleFavourite(fixture)">
								<svg xmlns="http://www.w3.org/2000/svg" width="12" height="11.143" viewBox="0 0 12 11.143">
									<path :style="{ fill: fixture.favourite ? '#9DC137' : '' }" id="Icon_ionic-ios-star" :class="`fixture-${fixture?.id}`" data-name="Icon ionic-ios-star" d="M12.67,5.545H8.729l-1.2-3.573a.434.434,0,0,0-.814,0l-1.2,3.573H1.554a.43.43,0,0,0-.429.429.315.315,0,0,0,.008.072.412.412,0,0,0,.179.3L4.551,8.63,3.308,12.244a.43.43,0,0,0,.147.482.414.414,0,0,0,.241.1.525.525,0,0,0,.268-.1l3.161-2.253,3.161,2.253a.5.5,0,0,0,.268.1.385.385,0,0,0,.238-.1.425.425,0,0,0,.147-.482L9.7,8.63l3.212-2.3.078-.067a.449.449,0,0,0,.139-.287A.454.454,0,0,0,12.67,5.545Z" transform="translate(-1.125 -1.688)" fill="#4e545d" />
								</svg>
							</div>
							<div @click="goToFixture(fixture)" class="race-time">
								<span>{{ datesStore.preFixtureBookDate(fixture.start_datetime) }}</span>
								<span>{{ datesStore.preFixtureBookTime(fixture.start_datetime) }}</span>
							</div>
						</div>
						<div @click="goToFixture(fixture)" class="race-info-right">
							<div class="race-countdown">
								<span>
									<svg xmlns="http://www.w3.org/2000/svg" width="11" height="11" viewBox="0 0 11 11">
										<g id="clock" transform="translate(0.5 0.5)">
											<g id="Icon_feather-clock" data-name="Icon feather-clock" transform="translate(-1 -1)">
											<path id="Path_907" data-name="Path 907" d="M11,6A5,5,0,1,1,6,1a5,5,0,0,1,5,5Z" fill="none" stroke="#ffbb02" stroke-linecap="round" stroke-linejoin="round" stroke-width="1"/>
											<path id="Path_908" data-name="Path 908" d="M6,3V6L8,7" fill="none" stroke="#ffbb02" stroke-linecap="round" stroke-linejoin="round" stroke-width="1"/>
											</g>
										</g>
									</svg>
								</span>
								<p>{{ moment(fixture.start_datetime).diff(moment(), 'minutes') }} {{ $t('racing.minutes_short') }}</p>
							</div>
						</div>
					</div>
				</div>
			</div>
			<!-- element end -->
		</div>
	</div>
</template>

<style scoped>
.sidebar-items {
	cursor: pointer;
}

</style>
