import { defineStore } from "pinia";

export const SportsData = defineStore({
  id: "SportsData",
  state: () => ({
    available_virtual_live_sports: [
      1, // Soccer
      2, // Tennis
      3, // Basketball
      4, // Ice Hockey
      5, // Handball
      7, // Water Polo
      8, // VolleyBall
      9, // Badminton
      10, // Table Tennis
      11, // Futsal
    ],
    sport_slugs: {
      1: "Soccer",
      2: "Tennis",
      3: "Basketball",
      4: "Ice Hockey",
      5: "Handball",
      6: "Baseball",
      7: "Water Polo",
      8: "Volleyball",
      9: "Badminton",
      10: "Table Tennis",
      11: "Futsal",
      12: "Boxing",
      13: "American Football",
      14: "Horse Racing",
      15: "Snooker",
      16: "Counter Strike",
      17: "MMA",
      18: "Politics",
      19: "Archery",
      20: "Greyhounds",
      21: "Dota 2",
      22: "Cricket",
      23: "Valorant",
      24: "Rugby Union",
      25: "Floorball",
      26: "Curling",
      27: "League of Legends",
      28: "Chess",
      29: "King of Glory",
      30: "Hockey",
      31: "Bowls",
      32: "Pistol Shooting",
      33: "Beach Volleyball",
      34: "Trotting",
      35: "Formula 1",
      36: "Darts",
      37: "Golf",
      38: "Archery Shooting",
      39: "Aussie Rules",
      40: "Rugby League",
      41: "Beach Soccer",
      42: "Netball",
      43: "Squash",
      44: "Cycling",
      45: "Gaelic Football",
      46: "Shooting",
      48: "Lacrosse",
    },
    sport_scoreboard_slugs: {
      1: "Soccer",
      2: "Tennis",
      3: "Basketball",
      4: "IceHockey",
      5: "Handball",
      6: "Baseball",
      8: "Volleyball",
      9: "Badminton",
      10: "TableTennis",
      11: "Futsal",
      15: "Snooker",
      16: "CounterStrike",
      21: "Dota2",
      22: "Cricket",
      23: "Valorant",
      27: "LeagueOfLegends",
      29: "KingOfGlory",
      30: "Hockey",
      32: "PistolShooting",
    },
  }),
  getters: {
    has_fixture_clock: () => (sport_external_id) => {
      sport_external_id = parseInt(sport_external_id);
      return [
        1, // Soccer
        3, // Basketball
        4, // Ice Hokey
        5, // Handball
        7, // Water Polo
        11, // Futsal
        12, // Boxing
        13, // American Football,
        17, // MMA
        24, // Rugby Union
        39, // aussie_rules
        40, // rugby_league
        41, // beach_soccer
        45, // Gaelic Football
      ].includes(sport_external_id);
    },
    sport_has_three_scores: () => (sport_id) => {
      sport_id = parseInt(sport_id);
      return [
        2, // Tennis
      ].includes(sport_id);
    },
    sport_has_two_scores: () => (sport_id) => {
      sport_id = parseInt(sport_id);
      return [
        8, // VolleyBall
        9, // Badminton
        10, // Table Tennis
        15, // Snooker
        16, // Counter Strike
        21, // Dota 2
        23, // Valorant
        27, // League of Legends
        29, // King of Glory
        33, // Beach Volleyball
        43, // Squash
      ].includes(sport_id);
    },
    sport_has_one_player: () => (sport_id) => {
      sport_id = parseInt(sport_id);
      return [
        32, // Pistol Shooting
        38, // Archery Shooting
        37, // Golf
        14, // Horse Racing
        20, // Greyhounds
        34, // Trotting
        35, // Formula 1
      ].includes(sport_id);
    },
  },
  actions: {},
});
