<script setup>
// eslint-disable-next-line no-unused-vars
import { closeModal, confirmModal } from '@kolirt/vue-modal'
import { ref, watch, onMounted, computed } from 'vue';
import commonSvgs from '@/assets/svgs/commonSvgs.json';
import { useLoginStore } from '@/stores/login';
import router from '@/router/router';
import { useI18n } from 'vue-i18n';

const { t } = useI18n();

const props = defineProps({
	mobile_number: null,
	mobile_country_id: null,
	account_unlock: null
});
const countdown = ref(0);
const loginStore = useLoginStore()
const svg_data = commonSvgs;
const verificationCode = ref(["", "", "", ""]); // Array to hold each input's value
const concatenatedCode = ref(""); // String to hold the concatenated code
const loading = ref(false);
const resend = ref(false);
const error_message_resend = ref(null);
const error_message_code = ref(null);
const success_message_resend = ref(null);
const success_message_code = ref(null);
let countdownInterval = null;

const handleInput = (index, event) => {
	const value = event.target.value;
	if (value.length === 1) {
		verificationCode.value[index] = value;
		if (index < 3) {
			document.getElementById(`input-${index + 1}`).focus();
		}
	}
};

const minutes = computed(() => String(Math.floor(countdown.value / 60)).padStart(2, '0'));
const seconds = computed(() => String(countdown.value % 60).padStart(2, '0'));

const startCountdown = () => {
	countdown.value = 300; // Set the countdown start value
	countdownInterval = setInterval(() => {
		if (countdown.value > 0) {
			countdown.value--;
		} else {
			clearInterval(countdownInterval);
		}
	}, 1000);
};

const handleKeydown = (index, event) => {
	if (event.key === 'Backspace' && index > 0 && event.target.value.length === 0) {
		document.getElementById(`input-${index - 1}`).focus();
	}
};

watch(verificationCode.value, (newValue) => {
	concatenatedCode.value = newValue.join("");

	if (concatenatedCode.value.length < 4) error_message_code.value = null

	if (concatenatedCode.value.length === 4) {

		let payload = {
			token: concatenatedCode.value,
			mobile_number: props.mobile_number,
			mobile_country_id: props.mobile_country_id,
			account_unlock: props.account_unlock
		}

		loginStore.checkPasswordResetTokenMobile(payload).then((response) => {
			success_message_code.value = response.message
			if (props.account_unlock == true) {
				router.push({ name: 'accountUnlockMobile' , params: { token: response.token }, query: { mobile_number: props.mobile_number, mobile_country_id: props.mobile_country_id }, state: { int_token: concatenatedCode.value } })
			} else {
				router.push({ name: 'resetPasswordMobile' , params: { token: response.token }, query: { mobile_number: props.mobile_number, mobile_country_id: props.mobile_country_id }, state: { int_token: concatenatedCode.value } })
			}
			confirmModal({ show_login: false })
		}).catch((error) => {
			if (error.data.errors) error_message_code.value = error.data.message;
			else error_message_code.value = error.data.error;
		});
	}
});

const forgotPasswordMobile = () => {

	if (props.mobile_prefix == null && props.mobile_prefix == '') {error_message_resend.value = t('account.register.error_mobile_number_required');}
	else if (props.mobile_number == null && props.mobile_number == '') {error_message_resend.value = t('account.register.error_mobile_number_required');}
	else if (!/^[0-9]+$/.test(props.mobile_number) && props.mobile_number) {error_message_resend.value = t('account.register.error_only_numbers');}
	else {
		let payload = {
			mobile_number: props.mobile_number,
			mobile_country_id: props.mobile_country_id,
			resend: resend.value
		}

		error_message_resend.value = null;
		success_message_resend.value = null;
		loading.value = true;
		loginStore.forgotPasswordMobile(payload).then(response => {
			success_message_resend.value = response.message;
			loading.value = false;
			resend.value = false;
			startCountdown();
			setTimeout(() => {
				success_message_resend.value = null;
			}, 3000);
		}).catch(error => {
			if (error.data.errors) error_message_resend.value = error.data.message;
			else error_message_resend.value = error.data.message;
			loading.value = false;
		})
	}
}

const resendSMS = () => {
	resend.value = true;
	forgotPasswordMobile();
}

onMounted(() => {
	// Focus on the first input when the component is mounted
	setTimeout(() => {
		document.getElementById('input-0').focus();
	}, 500);
	startCountdown()
});

</script>
<template>
	<div class="modal">
		<div class="modal-content">
			<a class="close-button" @click="confirmModal( {show_login: false} )" style="cursor: pointer;">
				<object v-html="svg_data.form_cross"></object>
			</a>
			<h4>{{ $t('account.register.verification_code_sent') }}</h4>
			<p>{{ $t('account.login.enter_verification_code') }}</p>
			<span v-if="error_message_code" style="color: red;">{{ error_message_code }}</span>
			<span v-if="success_message_code" style="color: green;">{{ success_message_code }}</span>
			<div class="code-input">
				<input v-for="(char, index) in verificationCode" :key="index" :id="`input-${index}`" type="text" maxlength="1" pattern="\d*" v-model="verificationCode[index]" @input="event => handleInput(index, event)" @keydown="event => handleKeydown(index, event)"/>
			</div>
			<div class="login-other-options">
				<div class="register-age-check register-form-field">
				</div>
				<div class="forgot-password">
					<p @click="forgot_password = true">{{ $t('account.login.sms_not_received') }}<button @click="resendSMS" :disabled="countdown > 0" class="resend">{{ $t('account.login.resend') }}</button></p>
					<span v-if="error_message_resend" style="color: red;">{{ error_message_resend }}</span>
					<span v-if="success_message_resend" style="color: green;">{{ success_message_resend }}</span>
					<div>
						<span class="countdown" v-if="countdown > 0">
							{{ $t('account.login.resend_availability') }} {{ minutes }}:{{ seconds }}
						</span>
					</div>
				</div>
			</div>
			<button @click="confirmModal( {show_login: false } )" class="close">Close</button>
		</div>
	</div>
</template>

<style scoped>
/* Modal Container */
.modal {
	display: flex;
	justify-content: center;
	align-items: center;
	position: fixed;
	z-index: 1000;
	left: 0;
	top: 0;
	width: 100%;
	height: 100%;
	background-color: rgba(0, 0, 0, 0.5); /* Black with opacity */
}

/* Modal Content */
.modal-content {
	background-color: #131C27; /* Dark background */
	color: #fff; /* White text */
	padding: 20px;
	border-radius: 10px;
	text-align: center;
	position: relative;
	width: 400px;
	max-width: 80%;
}

/* Close Button */
.close-button {
	position: absolute;
	top: 10px;
	right: 10px;
	color: #fff;
	font-size: 24px;
	cursor: pointer;
}

/* Headline */
.modal-content h4 {
	color: #9DC137; /* Lime green color */
	margin-bottom: 10px;
}

/* Verification Code Input Container */
.code-input {
	display: flex;
	justify-content: space-between;
	margin: 20px 35px;
	width: 80%;
}

/* Verification Code Input Fields */
.code-input input {
	width: 40px;
	height: 50px;
	font-size: 24px;
	text-align: center;
	border: none;
	border-bottom: 2px solid #fff;
	background: none;
	color: #fff;
	outline: none;
}

.code-input input::placeholder {
	color: #fff;
	opacity: 0.5;
}

/* Close Button */
.close {
	background-color: #ffcc00; /* Yellow background */
	color: #000; /* Black text */
	border: none;
	padding: 10px 20px;
	border-radius: 5px;
	cursor: pointer;
	font-size: 16px;
	margin-top: 10px;
}

.close:hover {
	background-color: #e6b800; /* Darker yellow on hover */
}


/* Close Button */
.resend {
	background-color: rgba(0, 0, 0, 0.5); /* Yellow background */
	color: #ffff;
	border: 1px solid #ffff;
	padding: 0px 12px;
	border-radius: 20px;
	cursor: pointer;
	font-size: 16px;
	margin-top: 10px;
	margin-left: 10px;
}

.resend:not(:disabled):hover {
	background-color: #9DC137; /* Darker yellow on hover */
}

.countdown {
	width: 100%;
	margin-top:10px;
}
</style>