 /* eslint-disable */
import { defineStore } from 'pinia';
import {applyPatch} from 'rfc6902'
import _ from 'lodash';
import { useInitializeStore } from './initialize';
import { useMarketsStore } from './markets';
import { useI18n } from 'vue-i18n';
import { useFavouritesStore } from './favourites';

// Scoreboards
// import SoccerScoreboard from '../components/live_sports/eventview/fixturebook/scoreboards/Soccer.vue'
// import TennisScoreboard from '../components/live_sports/eventview/fixturebook/scoreboards/Tennis.vue'
// import BasketballScoreboard from '../components/live_sports/eventview/fixturebook/scoreboards/Basketball.vue'
// import VolleyScoreboard from '../components/live_sports/eventview/fixturebook/scoreboards/Volleyball.vue'
// import TableTennisScoreboard from '../components/live_sports/eventview/fixturebook/scoreboards/TableTennis.vue'
// import IceHockeyScoreboard from '../components/live_sports/eventview/fixturebook/scoreboards/IceHockey.vue'
// import WaterPoloScoreboard from '../components/live_sports/eventview/fixturebook/scoreboards/WaterPolo.vue'
// import HandballScoreboard from '../components/live_sports/eventview/fixturebook/scoreboards/Handball.vue'
// import FutsalScoreboard from '../components/live_sports/eventview/fixturebook/scoreboards/Futsal.vue'
// import CounterStrikeScoreboard from '../components/live_sports/eventview/fixturebook/scoreboards/CounterStrike.vue'
// import DotaScoreboard from '../components/live_sports/eventview/fixturebook/scoreboards/Dota2.vue'
// import ValorantScoreboard from '../components/live_sports/eventview/fixturebook/scoreboards/Valorant.vue'
// import LoLScoreboard from '../components/live_sports/eventview/fixturebook/scoreboards/LeagueOfLegends.vue'
// import KingOfGloryScoreboard from '../components/live_sports/eventview/fixturebook/scoreboards/KingOfGlory.vue'
// import BadmintonScoreboard from '../components/live_sports/eventview/fixturebook/scoreboards/Badminton.vue'
// import AmericanFootballScoreboard from '../components/live_sports/eventview/fixturebook/scoreboards/AmericanFootball.vue'
// import FieldHockeyScoreboard from '../components/live_sports/eventview/fixturebook/scoreboards/FieldHockey.vue'
// import AussieRulesScoreboard from '../components/live_sports/eventview/fixturebook/scoreboards/AussieRules.vue'
// import BeachVolleyballScoreboard from '../components/live_sports/eventview/fixturebook/scoreboards/BeachVolleyball.vue'
// import SquashScoreboard from '../components/live_sports/eventview/fixturebook/scoreboards/Squash.vue'
// import FloorballScoreboard from '../components/live_sports/eventview/fixturebook/scoreboards/Floorball.vue'
// import BeachSoccerScoreboard from '../components/live_sports/eventview/fixturebook/scoreboards/BeachSoccer.vue'
// import RugbyUnionScoreboard from '../components/live_sports/eventview/fixturebook/scoreboards/RugbyUnion.vue'
// import RugbyLeagueScoreboard from '../components/live_sports/eventview/fixturebook/scoreboards/RugbyLeague.vue'
// import PistolShootingScoreboard from '../components/live_sports/eventview/fixturebook/scoreboards/PistolShooting.vue'
// import ArcheryScoreboard from '../components/live_sports/eventview/fixturebook/scoreboards/Archery.vue'
// import SnookerScoreboard from '../components/live_sports/eventview/fixturebook/scoreboards/Snooker.vue'
// import ArcheryShootingScoreboard from '../components/live_sports/eventview/fixturebook/scoreboards/ArcheryShooting.vue'
// import CricketScoreboard from '../components/live_sports/eventview/fixturebook/scoreboards/Cricket.vue'
// import GolfScoreboard from '../components/live_sports/eventview/fixturebook/scoreboards/Golf.vue'
// import BaseballScoreboard from '../components/live_sports/eventview/fixturebook/scoreboards/Baseball.vue'
// import DefaultScoreboard from '../components/live_sports/eventview/fixturebook/scoreboards/Default.vue'

export const useFixtureStore = defineStore('fixture', {
	state: () => ({
		fixture: null,
		fixture_loading: false,
		selected_market_group: 'all',
		mobile_active_tab: 'scoreboard',
		market_search: '',
	}),
	getters: {
		fixtureBook(state) {
			let fixture_sportbook = _.cloneDeep(state.fixture);
			if (_.isEmpty(fixture_sportbook)) return {};

			const favouritesStore = useFavouritesStore();
			if (state.selected_market_group == null) state.selected_market_group == 'all';

			fixture_sportbook.markets = _.filter(fixture_sportbook.markets, function (market) {
				if (!market.hasOwnProperty('visible')) return market;
				else if (market.visible == true) return market;
			});

			if (state.market_search != '') {
				fixture_sportbook.markets = _.filter(fixture_sportbook.markets, function (market) {
					return market.name.toLowerCase().includes(state.market_search.toLowerCase());
				});
			}

			let markets = [];
			let favourite_markets = [];

			if (state.selected_market_group == 'favourites') {
				favourite_markets = _.filter(fixture_sportbook.markets, function(market) {
					return favouritesStore.favouriteMarketIds.includes(market.id);
				});

				fixture_sportbook.markets = favourite_markets;
 			} else {
				markets = _.filter(fixture_sportbook.markets, function (market) {
					return market.groups.hasOwnProperty(state.selected_market_group);
				})

				favourite_markets = _.filter(markets, function(market) {
					return favouritesStore.favouriteMarketIds.includes(market.id);
				});
				fixture_sportbook.markets = _.sortBy(markets, [ function(market) { return market.groups[state.selected_market_group].sort; } ]);
			}

			if (state.selected_market_group != 'favourites') {
				let non_favourite_markets = _.filter(fixture_sportbook.markets, function(market) {
					return !favouritesStore.favouriteMarketIds.includes(market.id);
				});

				fixture_sportbook.markets = favourite_markets.concat(non_favourite_markets);
			}

			_.forEach(fixture_sportbook.markets, (market) => {
				// Sort odds of market and add odd_columns property
				market.odds = _.sortBy(market.odds, 'sort');
				market.odd_columns = _.groupBy(market.odds, 'col');

				if (favouritesStore.favouriteMarketIds.includes(market.id)) {
					market.favourite = true;
				}

				// When markets dont have col, split odds in the correct available order by their sort
				if (market.odd_columns[null]) {
					let market_col = 0;
					_.forEach(market.odd_columns[null], (odd) => {

						if (!market.odd_columns[market_col]) market.odd_columns[market_col] = [];

						market.odd_columns[market_col].push(odd);
						market_col = market_col + 1;
						if (market_col == market.cols) market_col = 0;
					});

					delete market.odd_columns[null];
				}

			});

			return fixture_sportbook;
		},
		selectedMarketGroup(state) {
			return state.selected_market_group;
		},
		fixtureClockTime: (state, getters) => (fixture) => {
			const initializeStore = useInitializeStore();
			const { t } = useI18n();
	
			let seconds_diff = 0;
			let calculated_seconds = 0;
			if (fixture.statistics.clock_status == 'running') {
				seconds_diff = Number(initializeStore.server_timestamp) - Number(fixture.statistics.clock_timestamp);
				if (fixture.statistics.clock_direction == 'up') {
					calculated_seconds = Number(fixture.statistics.clock_seconds) + seconds_diff;
				} else if (fixture.statistics.clock_direction == 'down') {
					calculated_seconds = Number(fixture.statistics.clock_seconds) - seconds_diff;
				}
			} else if (fixture.statistics.clock_status == 'stopped') {
				calculated_seconds = Number(fixture.statistics.clock_seconds);
			}

			if (calculated_seconds < 0) calculated_seconds = 0;

			if (calculated_seconds > 8400) calculated_seconds = 8434;

			// Calculate clock_time 'mm:ss'
			let mins = ~~(calculated_seconds / 3600) * 60 + ~~((calculated_seconds % 3600) / 60);
			let secs = ~~calculated_seconds % 60;
			let time = '';
			time += (mins < 10 ? '0' : '') + mins + ':' + (secs < 10 ? '0' : '');
			time += '' + secs;

			if (fixture.statistics.clock_status == 'stopped' && fixture.statistics.clock_seconds == 0 && fixture.statistics.clock_direction == 'up' && fixture.statistics.half != 'PN') {
				return t('scoreboard.starting_soon');
			} else if (fixture.statistics.half == 'PN') {
				return t('scoreboard.penalties')
			} else {
				if (fixture.statistics.injury_time != null) {
					return time + ' +' + parseInt(fixture.statistics.injury_time);
				}
				return time;
			}
		},
		fixtureState: (state, getters) => (fixture) => {
			if (fixture.statistics.game != null) return 'G ' + fixture.statistics.game;
			if (fixture.statistics.half != null) return fixture.statistics.half;
			if (fixture.statistics.period != null) return fixture.statistics.period;
			if (fixture.statistics.innings != null) return 'Innings ' + fixture.statistics.innings

			return null;
		},
		marketGroups(state) {
			if (state.fixture == null) return [];

			const favouritesStore = useFavouritesStore();
			let markets = _.cloneDeep(state.fixture.markets);

			markets = _.filter(markets, function (market) {
				if (!market.hasOwnProperty('visible')) return market;
				else if (market.visible == true) return market;
			});

			if (state.market_search != '') {
				markets = _.filter(markets, function (market) {
					return market.name.toLowerCase().includes(state.market_search.toLowerCase());
				});
			}

			let market_groups = {};
			_.forEach(markets, (market) => {
				for (let index = 0; index < Object.keys(market.groups).length; index++) {
					const group = Object.keys(market.groups)[index];
					if (market_groups[group]) {
						market_groups[group]++;
					} else {
						market_groups[group] = 1;
					}
				}

				if (favouritesStore.favouriteMarketIds.includes(market.id)) {
					if (market_groups['favourites']) {
						market_groups['favourites']++;
					} else {
						market_groups['favourites'] = 1;
					}
				}

			})

			if (!Object.keys(market_groups).includes(state.selected_market_group)) state.selected_market_group = 'all';

			if (_.isEmpty(market_groups)) return {'all': 0};

			return market_groups;
		},
		sortedMarketGroups: (state) => (market_groups) => {
			if (state.fixture == null) return;

			const marketsStore = useMarketsStore();
			let sport_sorted_market_groups = marketsStore.sortedMarketGroups(state.fixture.sport_id);

			let market_group_keys = Object.keys(market_groups);

			market_group_keys.sort(function (a, b) {
				if (sport_sorted_market_groups.includes(a) && !sport_sorted_market_groups.includes(b)) return 1;
				if (!sport_sorted_market_groups.includes(a) && sport_sorted_market_groups.includes(b)) return 1;

				if (!sport_sorted_market_groups.includes(a) && !sport_sorted_market_groups.includes(b)) return 0;

				if (sport_sorted_market_groups.indexOf(a) > sport_sorted_market_groups.indexOf(b)) return 1;

				if (sport_sorted_market_groups.indexOf(a) < sport_sorted_market_groups.indexOf(b)) return -1;

				return 0;
			});

			if (market_group_keys.includes('favourites')) {
				market_group_keys = ['favourites', ...market_group_keys.filter(group => group != 'favourites')];
			}

			return market_group_keys;
		},
		// getScoreboardComponent(state) {
		// 	if (state.fixture == null) return;

		// 	switch (state.fixture.sport_id) {
		// 		case 1:
		// 			return SoccerScoreboard
		// 			break;
		// 		case 2:
		// 			return TennisScoreboard
		// 			break;
		// 		case 3:
		// 			return BasketballScoreboard
		// 			break;
		// 		case 4:
		// 			return IceHockeyScoreboard
		// 			break;
		// 		case 5:
		// 			return HandballScoreboard
		// 			break;
		// 		case 6:
		// 			return BaseballScoreboard
		// 			break;
		// 		case 7:
		// 			return WaterPoloScoreboard
		// 			break;
		// 		case 8:
		// 			return VolleyScoreboard
		// 			break;
		// 		case 9:
		// 			return BadmintonScoreboard
		// 			break;
		// 		case 10:
		// 			return TableTennisScoreboard
		// 			break;
		// 		case 11:
		// 			return FutsalScoreboard
		// 			break;
		// 		case 13:
		// 			return AmericanFootballScoreboard
		// 			break;
		// 		case 15:
		// 			return SnookerScoreboard
		// 			break;
		// 		case 16:
		// 			return CounterStrikeScoreboard
		// 			break;
		// 		case 19:
		// 			return DefaultScoreboard
		// 			break;
		// 		case 21:
		// 			return DotaScoreboard
		// 			break;
		// 		case 22:
		// 			return CricketScoreboard
		// 			break;
		// 		case 23:
		// 			return ValorantScoreboard
		// 			break;
		// 		case 24:
		// 			return RugbyUnionScoreboard
		// 			break;
		// 		case 25:
		// 			return FloorballScoreboard
		// 			break;
		// 		case 27:
		// 			return LoLScoreboard
		// 			break;
		// 		case 29:
		// 			return KingOfGloryScoreboard
		// 			break;
		// 		case 30:
		// 			return FieldHockeyScoreboard
		// 			break;
		// 		case 32:
		// 			return PistolShootingScoreboard
		// 			break;
		// 		case 33:
		// 			return BeachVolleyballScoreboard
		// 			break;
		// 		case 37:
		// 			return GolfScoreboard
		// 			break;
		// 		case 38:
		// 			return ArcheryShootingScoreboard
		// 			break;
		// 		case 39:
		// 			return AussieRulesScoreboard
		// 			break;
		// 		case 40:
		// 			return RugbyLeagueScoreboard
		// 			break;
		// 		case 41:
		// 			return BeachSoccerScoreboard
		// 			break;
		// 		case 43:
		// 			return SquashScoreboard
		// 			break;
		// 		default:
		// 			return DefaultScoreboard
		// 			break;
		// 	}
		// },
	},
	actions: {
		setFixture(data) {
			this.fixture = data;
		},
		updateFixture(data) {
			applyPatch(this.fixture, data.patch);
		},
		emptyFixture(data) {
			this.fixture = null;
		},
		setSelectedMarketGroup(data) {
			this.selected_market_group = data;
		},
		setFixtureLoading(data) {
			this.fixture_loading = data;
		},
		setMobileActiveTab(tab) {
			this.mobile_active_tab = tab;
		}
	},
})