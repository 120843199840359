<script setup>
/* eslint-disable */

import { closeModal, openModal } from '@kolirt/vue-modal'
import { useGeneralStore } from '@/stores/general';
import { useLoginStore } from '@/stores/login';
import { useI18n } from 'vue-i18n';
import { ref } from 'vue';
import Swal from 'sweetalert2';
import commonSvgs from '@/assets/svgs/commonSvgs.json'
import MobileCodeVerify from './MobileCodeVerify.vue';

const generalStore = useGeneralStore();
const loginStore = useLoginStore();
const { t } = useI18n();

const svgs_data = commonSvgs
const is_country_ghana = process.env.VUE_APP_COUNTRY == 'GHANA';
const error_message_forgot = ref('');
const loading = ref(false);

const props = defineProps({
	mobile_number: {
		type: Number,
		required: true
	},
	country_code: {
		type: String,
		required: false
	},
	email: {
		type: String,
		required: false
	}
})

const sendResetRequest = () => {
	loading.value = true;

	if (is_country_ghana) {
		let payload = {
			mobile_number: props.mobile_number,
			mobile_country_id: props.country_code,
		}

		loginStore.forgotPasswordMobile(payload).then(response => {
			loading.value = false;

			openModal(MobileCodeVerify, { mobile_number: props.mobile_number, mobile_country_id: props.country_code }).then((data) => {
				if (data.show_login == false) closeModal();
			}).catch(() =>{});
		}).catch(error => {
			closeModal();
			setTimeout(() => {
				fireSwal('error', error.data.message);
			}, 500);
			loading.value = false;
		})
	} else {
		loginStore.forgotPassword(props.email).then(response => {
			closeModal();
			setTimeout(() => {
				fireSwal('success', response.message);
			}, 500);
			loading.value = false;
		}).catch(error => {
			if (error.data.errors) error_message_forgot.value = error.data.errors.email[0];
			else error_message_forgot.value = error.data.message;
			closeModal();
			setTimeout(() => {
				fireSwal('error', error_message_forgot.value);
			}, 500);
			loading.value = false;
		})
	}
}

const fireSwal = (alert_type, alert_message) => {
	Swal.fire({
		text: alert_message,
		icon: alert_type,
		confirmButtonText: t('account.register.close'),
	});
}

</script>
<template>
	<div class="modal-outer verify-email-popup" ref="modal">
		<!-- overlay -->
		<div class="overlay"></div>
		<!-- modal -->
		<div class="modal">
			<button class="close" @click="closeModal()"  v-html="svgs_data.popup_cross"></button>
			<div class="password-reset-main">
				<div class="popup-message">
					<h5>{{ $t('account.first_login.greeting', { platform_name: generalStore.getGeneral.site_name }) }}</h5>
					<p>{{ $t('account.first_login.first_paragraph', { platform_name: generalStore.getGeneral.site_name }) }}</p>
					<br>
					<p>{{ $t('account.first_login.second_paragraph') }}</p>
					<br>
					<p>{{ $t('account.first_login.third_paragraph') }}</p>
					<br>
					<p>{{ $t('account.first_login.fourth_paragraph') }}</p>
					<p>{{ $t('account.first_login.outro', { platform_name: generalStore.getGeneral.site_name }) }}</p>
				</div>
				<div class="button-container">
					<button class="btn btn-secondary" type="button" @click="sendResetRequest()" v-if="!loading">{{ $t('account.first_login.lets_start') }}</button>
					<button class="btn btn-secondary" type="button" v-else><div class="loader-main"></div></button>
				</div>
			</div>
		</div>
	</div>
</template>
<style scoped>
.modal-outer .modal .modal-header {
	text-align: left;
}
.popup-message {
	text-align: left;
}
.popup-message h5{
	color: #9DC137;
	padding-bottom: 6px;
	font-size: 20px;
}
.button-container {
	text-align: center;
}
</style>