/* eslint-disable */
import { defineStore } from 'pinia';
import _ from 'lodash';
import { useCustomerStore } from './customer';
import Email from '@/components/register/Email.vue';
import Username from '@/components/register/Username.vue';
import Password from '@/components/register/Password.vue';
import TcCheck from '@/components/register/TcCheck.vue';
import Firstname from '@/components/register/Firstname.vue';
import Lastname from '@/components/register/Lastname.vue';
import Dob from '@/components/register/Dob.vue';
import Gender from '@/components/register/Gender.vue';
import MobileNumber from '@/components/register/MobileNumber.vue';
import MobileCountryId from '@/components/register/MobileCountryId.vue';
import Country from '@/components/register/Country.vue';
import City from '@/components/register/City.vue';
import StreetName from '@/components/register/StreetName.vue';
import StreetNumber from '@/components/register/StreetNumber.vue';
import PostCode from '@/components/register/PostCode.vue';
import Language from '@/components/register/Language.vue';
import Currency from '@/components/register/Currency.vue';
import RadioFields from '@/components/register/RadioFields.vue';
import NationalIdNumber from '@/components/register/NationalIdNumber.vue';
import ReferralCode from '@/components/register/ReferralCode.vue';
import DocumentIdType from '@/components/register/DocumentIdType.vue';
import DocumentNumber from '@/components/register/DocumentNumber.vue';
import VerificationNumber from '@/components/register/VerificationNumber.vue';
import VerifyBy from '@/components/register/VerifyBy.vue';

export const useRegisterStore = defineStore('register', {
	state: () => ({
		enabled_registration_fields: null,
		register_input_fields: {
			email: null,
			country_id: null,
			username: null,
			password: null,
			password_confirmation: null,
			gender: null,
			firstname: null,
			lastname: null,
			dob_day: null,
			dob_month: null,
			dob_year: null,
			mobile_country_id: null,
			mobile_number: null,
			city: null,
			street_name: null,
			street_no: null,
			post_code: null,
			language_id: null,
			currency_id: null,
			national_id_number: null,
			receive_internal_message: 'yes',
			receive_emails: 'yes',
			receive_calls: 'yes',
			receive_sms: 'yes',
			referral_code: null,
			tc_check: false,
			registration_document_id_type: null,
			document_id_number: null,
			number_verification: null,
			verify_by: null,
		},
		register_password: {
			has_minimum_length: false,
			has_letter: false,
			has_number: false,
			required: false
		},
		register_errors: {
			email: [],
			country_id: [],
			username: [],
			password: [],
			password_confirmation: [],
			gender: [],
			firstname: [],
			lastname: [],
			dob_day: [],
			dob_month: [],
			dob_year: [],
			mobile_country_id: [],
			mobile_number: [],
			city: [],
			street_name: [],
			street_no: [],
			post_code: [],
			language_id: [],
			currency_id: [],
			national_id_number: [],
			receive_internal_message: [],
			receive_emails: [],
			receive_calls: [],
			receive_sms: [],
			tc_check: [],
			registration_document_id_type: [],
			document_id_number: [],
			verify_by: [],
			number_verification: []
		},
	}),
	getters: {
		getEnabledRegistrationFields(state) {
			return state.enabled_registration_fields;
		},
		// Get a list of the components
		getFieldComponentName: () => (field) => {
			let field_components = {
				'email': Email,
				'country_id': Country,
				'username': Username,
				'password': Password,
				'gender': Gender,
				'firstname': Firstname,
				'lastname': Lastname,
				'dob': Dob,
				'city': City,
				'street_name': StreetName,
				'street_no': StreetNumber,
				'post_code': PostCode,
				'language_id': Language,
				'currency_id': Currency,
				'national_id_number': NationalIdNumber,
				'radio_fields': RadioFields,
				'referral_code': ReferralCode,
				'tc_check': TcCheck,
				'mobile_number': MobileNumber,
				'mobile_country_id': MobileCountryId,
				'number_verification': VerificationNumber,
				'registration_document_id_type': DocumentIdType,
				'document_id_number': DocumentNumber,
				'verify_by': VerifyBy
			}

			return field_components[field];
		},

		// Get validation functions for each component
		getFieldValidationFunction: () => (field) => {

			let field_validation_functions = {
				'email': ['validateEmail'],
				'country_id': ['validateCountry'],
				'username': ['validateUsername'],
				'password': ['validatePassword', 'validatePasswordConfirmation'],
				'gender': ['validateGender'],
				'firstname': ['validateFirstname'],
				'lastname': ['validateLastname'],
				'dob': ['validateDobDay', 'validateDobMonth', 'validateDobYear'],
				'city': ['validateCity'],
				'street_name': ['validateStreetName'],
				'street_no': ['validateStreetNo'],
				'post_code': ['validatePostCode'],
				'language_id': ['validateLanguage'],
				'currency_id': ['validateCurrency'],
				'national_id_number': ['validateNationalIdNumber'],
				'tc_check': ['validateTcCheck'],
				'mobile_number': ['validateMobileNumber'],
				'mobile_country_id': ['validateMobilePrefix'],
				'radio_fields': [],
				'referral_code': [],
				'number_verification': [],
				'registration_document_id_type': ['validateDocumentIDType'],
				'document_id_number': ['validateDocumentNumber'],
			}

			return field_validation_functions[field];
		},
		// Get the max step number
		getAllSteps() {
			const enabled_fields = this.enabled_registration_fields;
			if (enabled_fields && enabled_fields.length > 0) {
				const max_step = _.maxBy(enabled_fields, 'step');

				return Number(max_step.step);
			}
		},
		// check if the email field is enable
		hasEmail() {
			const has_email = _.find(this.enabled_registration_fields, { slug: 'email' });
			if (has_email) return true;

			return false;
		},
		// Get components for each step
		getStepFieldComponents: (state) => (step) => {
			const enabled_fields = state.enabled_registration_fields;
			let fields = _.chain(enabled_fields).filter(function (field) { return field.step == step }).orderBy('sort').value();

			let components = [];
			var field_missing = true; // Flag to track whether certain radio fields are missing for conditional rendering.

			for (const field of fields) {
				if (field.slug == 'receive_emails' || field.slug == 'receive_calls' || field.slug == 'receive_internal_message' || field.slug == 'receive_sms') {
					// If the radio field is missing, add the radio component to the components array.
					if (field_missing) {
						components.push(state.getFieldComponentName('radio_fields'))
						field_missing = false;
					}
				} else {
					components.push(state.getFieldComponentName(field.slug));
				}
			}

			return components;
		},
		// Check if Number verification form field is enabled
		hasNumberVerification() {
			const has_number_verification = _.find(this.enabled_registration_fields, { slug: 'number_verification' });
			if (has_number_verification) return true;

			return false;
		},
	},
	actions: {
		// Find the step where a field belongs
		getFieldStep(field) {
			console.log(field);
			console.log(this.enabled_registration_fields);
			// Those 3 fields belong to the same component
			if (field == 'dob_day' || field == 'dob_month' || field == 'dob_year') {
				const registration_field = _.find(this.enabled_registration_fields, { slug: 'dob' });
				return registration_field.step;
			}

			const registration_field = _.find(this.enabled_registration_fields, { slug: field })
			return registration_field.step;
		},
		// Field validation for each step
		validateStep(step) {
			let validated = true;
			const enabled_fields = this.enabled_registration_fields;
			const field_component_slug = _.chain(enabled_fields).filter(function (field) { return field.step == step }).map('slug').value();
			let field_missing = true; // Flag to track whether certain radio fields are missing for conditional rendering.

			for (const field of field_component_slug) {
				let validation_function_names = null;

				// Check if the field is one of the specified radio fields. If the radio field is missing, set the validation function for radio fields.
				if (field == 'receive_emails' || field == 'receive_calls' || field == 'receive_internal_message' || field == 'receive_sms') {
					if (field_missing) {
						validation_function_names = this.getFieldValidationFunction('radio_fields');
						field_missing = false;
					}
				} else {
					validation_function_names = this.getFieldValidationFunction(field);
				}

				if (validation_function_names == null) continue;
				for (const field_validation of validation_function_names) {
					if (!this[field_validation]()) {
						validated = false;
					}
				}
			}

			return validated;
		},
		submitRegister(payload) {
			return global.axios.post(process.env.VUE_APP_API_URL + '/v2/auth/register', payload).then((response) => {
				useCustomerStore().auth_token = response.data.token
				return Promise.resolve(response.data.token);
			}).catch((error) => {
				let errors = error.response.data.errors;

				for (const input_field in errors) {
					if (this.register_errors.hasOwnProperty(input_field)) {
						this.register_errors[input_field].error = errors[input_field][0]
					}
				}

				return Promise.reject(error.response);
			})
		},
		initializeForm() {
			this.register_input_fields = {
				email: null,
				country_id: null,
				username: null,
				password: null,
				password_confirmation: null,
				gender: null,
				firstname: null,
				lastname: null,
				dob_day: null,
				dob_month: null,
				dob_year: null,
				mobile_country_id: null,
				mobile_number: null,
				city: null,
				street_name: null,
				street_no: null,
				post_code: null,
				language_id: null,
				currency_id: null,
				national_id_number: null,
				receive_internal_message: 'yes',
				receive_emails: 'yes',
				receive_calls: 'yes',
				receive_sms: 'yes',
				referral_code: null,
				tc_check: null,
				number_verification: null,
				verify_by: null,
			},
				this.register_password = {
					has_minimum_length: false,
					has_letter: false,
					has_number: false
				},
				this.register_errors = {
					email: [],
					country_id: [],
					username: [],
					password: [],
					password_confirmation: [],
					gender: [],
					firstname: [],
					lastname: [],
					dob_day: [],
					dob_month: [],
					dob_year: [],
					mobile_country_id: [],
					mobile_number: [],
					city: [],
					street_name: [],
					street_no: [],
					post_code: [],
					language_id: [],
					currency_id: [],
					national_id_number: [],
					receive_internal_message: [],
					receive_emails: [],
					receive_calls: [],
					receive_sms: [],
					tc_check: [],
					verify_by: [],
				}
		},
		// Sets the enabled fields from the request
		setEnabledRegistrationFields(data) {
			this.enabled_registration_fields = data;
		},
		//  FIELD VALIDATORS
		validatePasswordConfirmation() {
			if (this.register_input_fields.password_confirmation == null || this.register_input_fields.password_confirmation == '') {
				this.register_errors.password_confirmation = { error: 'required' };
				return false
			}
			if (this.register_input_fields.password && this.register_input_fields.password_confirmation) {
				if (this.register_input_fields.password != this.register_input_fields.password_confirmation) {
					this.register_errors.password_confirmation = { error: 'not_matching' };
					return false
				} else if (this.register_input_fields.password == this.register_input_fields.password_confirmation && this.register_input_fields.username == this.register_input_fields.password) {
					this.register_errors.password_confirmation = { error: 'username_password_same' };
					return false
				} else {
					this.register_errors.password_confirmation = { error: null };
					return true
				}
			}
		},
		validatePassword() {
			if (process.env.VUE_APP_COUNTRY == 'GHANA') {
				if (this.register_input_fields.password && this.register_input_fields.password.length >= 4) {
					if (!this.register_password.has_minimum_length) this.register_password.has_minimum_length = true;
				} else {
					if (this.register_password.has_minimum_length) this.register_password.has_minimum_length = false;
				}
				if (this.register_input_fields.password == null || this.register_input_fields.password == '') {
					this.register_password.required = false;
				} else {
					this.register_password.required = true;
				}

				if (this.register_password.has_minimum_length == false || this.register_password.required == false) {
					this.register_errors.password = { error: true }
					this.validatePasswordConfirmation();
					return false
				} else {
					this.register_errors.password = { error: false }
					this.validatePasswordConfirmation();
					return true
				}
			} else {
				if (this.register_input_fields.password && this.register_input_fields.password.length >= 8) {
					if (!this.register_password.has_minimum_length) this.register_password.has_minimum_length = true;
				} else {
					if (this.register_password.has_minimum_length) this.register_password.has_minimum_length = false;
				}

				if (this.register_input_fields.password && /\p{Lu}/u.test(this.register_input_fields.password)) {
					if (!this.register_password.has_letter) this.register_password.has_letter = true;
				} else {
					if (this.register_password.has_letter) this.register_password.has_letter = false;
				}

				if (this.register_input_fields.password && /\d/.test(this.register_input_fields.password)) {
					if (!this.register_password.has_number) this.register_password.has_number = true;
				} else {
					if (this.register_password.has_number) this.register_password.has_number = false;
				}

				if (this.register_input_fields.password && /\d/.test(this.register_input_fields.password)) {
					if (!this.register_password.has_number) this.register_password.has_number = true;
				} else {
					if (this.register_password.has_number) this.register_password.has_number = false;
				}

				if (this.register_input_fields.password == null || this.register_input_fields.password == '') {
					this.register_password.required = false;
				} else {
					this.register_password.required = true;
				}

				if (this.register_password.has_minimum_length == false || this.register_password.has_number == false || this.register_password.has_letter == false || this.register_password.required == false) {
					this.register_errors.password = { error: true }
					this.validatePasswordConfirmation();
					return false
				} else {
					this.register_errors.password = { error: false }
					this.validatePasswordConfirmation();
					return true
				}
			}
		},
		validateUsername() {
			if (!/^[a-zA-Z\d\s\p{L}]+$/gu.test(this.register_input_fields.username) && this.register_input_fields.username) {
				this.register_errors.username = { error: 'error_characters_and_numbers' };
				return false
			} else if (this.register_input_fields.username == null || this.register_input_fields.username == '') {
				this.register_errors.username = { error: 'username_required' };
				return false
			} else {
				this.register_errors.username = { error: null }
				this.validatePasswordConfirmation()
				return true
			}
		},
		validateEmail() {
			if (!/\S+@\S+\.\S+/.test(this.register_input_fields.email) && this.register_input_fields.email) {
				this.register_errors.email = { error: 'email_format' };
				return false
			} else if (this.register_input_fields.email == null || this.register_input_fields.email == '') {
				this.register_errors.email = { error: 'email_required' };
				return false
			} else {
				this.register_errors.email = { error: null };
				return true
			}
		},
		validateTcCheck() {
			if (this.register_input_fields.tc_check == false) {
				this.register_errors.tc_check = { error: 'error_tc_check' };
				return false;
			} else {
				this.register_errors.tc_check = { error: null };
				return true;
			}
		},
		validateFirstname() {
			if (!/^[a-zA-Z\s\p{L}]+$/gu.test(this.register_input_fields.firstname) && this.register_input_fields.firstname) {
				this.register_errors.firstname = { error: 'error_only_letter_characters' };
				return false
			} else if (this.register_input_fields.firstname == null || this.register_input_fields.firstname == '') {
				this.register_errors.firstname = { error: 'error_firstname_required' };
				return false
			} else {
				this.register_errors.firstname = { error: null };
				return true
			}
		},
		validateLastname() {
			if (!/^[a-zA-Z\s\p{L}]+$/gu.test(this.register_input_fields.lastname) && this.register_input_fields.lastname) {
				this.register_errors.lastname = { error: 'error_only_letter_characters' };
				return false
			} else if (this.register_input_fields.lastname == null || this.register_input_fields.lastname == '') {
				this.register_errors.lastname = { error: 'error_lastname_required' };
				return false
			} else {
				this.register_errors.lastname = { error: null };
				return true
			}
		},
		validateDobDay() {
			if (this.register_input_fields.dob_day == null) {
				this.register_errors.dob_day = { error: "Select day of birth" };
				return false;
			} else {
				this.register_errors.dob_day = { error: null };
				return true;
			}
		},
		validateDobMonth() {
			if (this.register_input_fields.dob_month == null) {
				this.register_errors.dob_month = { error: "Select month of birth" };
				return false;
			} else {
				this.register_errors.dob_month = { error: null };
				return true;
			}
		},
		validateDobYear() {
			if (this.register_input_fields.dob_year == null) {
				this.register_errors.dob_year = { error: "Select year of birth" };
				return false;
			} else {
				this.register_errors.dob_year = { error: null };
				return true;
			}
		},
		validateGender() {
			if (this.register_input_fields.gender == null) {
				this.register_errors.gender = { error: 'error_select_gender' };
				return false;
			} else {
				this.register_errors.gender = { error: null };
				return true;
			}
		},
		validateMobilePrefix() {
			if (this.register_input_fields.mobile_country_id == null) {
				this.register_errors.mobile_country_id = { error: 'error_select_mobile_prefix' };
				return false;
			} else {
				this.register_errors.mobile_country_id = { error: null };
				return true;
			}
		},
		validateMobileNumber() {
			if (!/^[0-9]+$/.test(this.register_input_fields.mobile_number) && this.register_input_fields.mobile_number) {
				this.register_errors.mobile_number = { error: 'error_only_numbers' };
				return false
			} else if (this.register_input_fields.mobile_number == null || this.register_input_fields.mobile_number == '') {
				this.register_errors.mobile_number = { error: 'error_mobile_number_required' };
				return false
			} else {
				this.register_errors.mobile_number = { error: null };
				return true
			}
		},
		validateCountry() {
			if (this.register_input_fields.country_id == null) {
				this.register_errors.country_id = { error: 'error_select_country' };
				return false;
			} else {
				this.register_errors.country_id = { error: null };
				return true;
			}
		},
		validateCity() {
			if (!/^[a-zA-Z\s\p{L}]+$/gu.test(this.register_input_fields.city) && this.register_input_fields.city) {
				this.register_errors.city = { error: 'error_only_latin_characters' };
				return false
			} else if (this.register_input_fields.city == null || this.register_input_fields.city == '') {
				this.register_errors.city = { error: 'error_city_required' };
				return false
			} else {
				this.register_errors.city = { error: null };
				return true
			}
		},
		validateStreetName() {
			if (!/^[a-zA-Z0-9\s\p{L}]+$/gu.test(this.register_input_fields.street_name) && this.register_input_fields.street_name) {
				this.register_errors.street_name = { error: 'error_only_characters_and_numbers' };
				return false
			} else if (this.register_input_fields.street_name == null || this.register_input_fields.street_name == '') {
				this.register_errors.street_name = { error: 'error_address_required' };
				return false
			} else {
				this.register_errors.street_name = { error: null };
				return true
			}
		},
		validateStreetNo() {
			if (!/^[a-zA-Z0-9\s\p{L}]+$/gu.test(this.register_input_fields.street_no) && this.register_input_fields.street_no) {
				this.register_errors.street_no = { error: 'error_only_characters_and_numbers' };
				return false;
			} else if (this.register_input_fields.street_no == null || this.register_input_fields.street_no == '') {
				this.register_errors.street_no = { error: 'error_address_number_required' };
				return false;
			} else {
				this.register_errors.street_no = { error: null };
				return true;
			}
		},
		validatePostCode() {
			if (this.register_input_fields.post_code && (this.register_input_fields.post_code.length < 4 || this.register_input_fields.post_code.length > 10)) {
				this.register_errors.post_code = { error: 'error_length_between' };
				return false
			}
			if (!/^[a-zA-Z0-9\s\p{L}]+$/gu.test(this.register_input_fields.post_code) && this.register_input_fields.post_code) {
				this.register_errors.post_code = { error: 'error_only_numbers' };
				return false;
			} else if (this.register_input_fields.post_code == null || this.register_input_fields.post_code == '') {
				this.register_errors.post_code = { error: 'error_post_code_required' };
				return false
			} else {
				this.register_errors.post_code = { error: null };
				return true
			}
		},
		validateLanguage() {
			if (this.register_input_fields.language_id == null) {
				this.register_errors.language_id = { error: 'error_select_language' };
				return false;
			} else {
				this.register_errors.language_id = { error: null };
				return true;
			}
		},
		validateCurrency() {
			if (this.register_input_fields.currency_id == null) {
				this.register_errors.currency_id = { error: 'error_select_currency' };
				return false;
			} else {
				this.register_errors.currency_id = { error: null };
				return true;
			}
		},
		validateNationalIdNumber() {
			if (!/^[0-9a-zA-Z\s\p{L}]+$/gu.test(this.register_input_fields.national_id_number) && this.register_input_fields.national_id_number) {
				this.register_errors.national_id_number = { error: 'error_only_characters_and_numbers' };
				return false;
			} else if (this.register_input_fields.national_id_number == null || this.register_input_fields.national_id_number == '') {
				this.register_errors.national_id_number = { error: 'error_national_id_required' };
				return false
			} else {
				this.register_errors.national_id_number = { error: null };
				return true;
			}
		},
		validateDocumentNumber() {
			if (!/^[0-9a-zA-Z\s\p{L}]+$/gu.test(this.register_input_fields.document_id_number) && this.register_input_fields.document_id_number) {
				this.register_errors.document_id_number = { error: 'error_only_characters_and_numbers' };
				return false;
			} else if (this.register_input_fields.document_id_number == null || this.register_input_fields.document_id_number == '') {
				this.register_errors.document_id_number = { error: 'error_document_id_number_required' };
				return false
			} else {
				this.register_errors.document_id_number = { error: null };
				return true;
			}
		},
		validateDocumentIDType() {
			if (this.register_input_fields.registration_document_id_type == null) {
				this.register_errors.registration_document_id_type = { error: 'error_select_registration_document_id' };
				return false;
			} else {
				this.register_errors.registration_document_id_type = { error: null };
				return true;
			}
		},
		validateVerifyBy() {
			if (this.register_input_fields.verify_by == null) {
				this.register_errors.verify_by = { error: 'error_select_verify_by' };
				return false;
			} else {
				this.register_errors.verify_by = { error: null };
				return true;
			}
		},
		validateVerificationNumber() {
			if (this.register_input_fields.verify_by == null) {
				this.register_errors.verify_by = { error: 'error_select_number_verification' };
				return false;
			} else {
				this.register_errors.verify_by = { error: null };
				return true;
			}
		},
		sendSms(payload) {
			return global.axios.post(process.env.VUE_APP_API_URL + '/send-code', payload).then((response) => {
				console.log(response.data)
				return Promise.resolve(response.data);
			}).catch((error) => {
				console.log(error)
				return Promise.reject(error);
			})
		},
	},
})