<script setup>
/* eslint-disable */
import { ref, onMounted, watch } from "vue";
import { useRouter } from 'vue-router';
import { useFixtureStore } from '@/stores/fixture';
import { useMarketsStore } from '@/stores/markets';
import { useLiveSportsStore } from '@/stores/live_sports';
import LeftSidebarFixture from '@/components/live_sports/left_sidebar/LeftSidebarFixture.vue';

const router = useRouter();
const fixtureStore = useFixtureStore();
const marketsStore = useMarketsStore();
const liveSportsStore = useLiveSportsStore();

const props = defineProps({
	competition: {
		type: Object,
		required: true,
	},
	competitions_opened: {
		required: true,
	}
});

const primary_overview_market = ref(marketsStore.sportOverviewMarkets(props.competition[0].sport_id)[0])
const competition_opened = ref(true);

onMounted(() => {
	primary_overview_market.value = marketsStore.sportOverviewMarkets(props.competition[0].sport_id)[0]

	competition_opened.value = props.competitions_opened;
})

watch(() => props.competitions_opened, (newValue, oldValue) => {
	competition_opened.value = newValue;
});

</script>

<template>
	<div class="accordion-item" :class="{'active': competition_opened}">
		<div class="accordion-header" @click="competition_opened = !competition_opened">
			<div class="country-info-inner">
				<div class="country-left">
					<!-- <div class="country-flag">
						<img :src="'/assets' + competition[0].region_icon_url" alt="flag-country">
					</div> -->
					<div class="country-name">
						<h3>{{ competition[0].region_name }} - {{ competition[0].competition_name }} </h3>
					</div>
				</div>
				
			</div>
		</div>
		<div class="accordion-content">
			<div class="sidebar-bet-info round-game-outer" v-for="(fixture, index) in competition" :key="index"  :class="{'active': fixture.id == fixtureStore.fixtureBook.id }">
				<LeftSidebarFixture :fixture="fixture" :primary_overview_market="primary_overview_market"/>
			</div>

		</div>
	</div>
</template>