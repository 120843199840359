import { defineStore } from 'pinia';

import { useCustomerStore } from './customer';

export const useBonusStore = defineStore('bonus', {
	state: () => ({
		freebet_history_loading: false,
		freespin_history_loading: false,
		freespin_active_loading: false,
		bonus_history_loading: false,
	}),
	getters: {
		//
	},
	actions: {
		fetchOffers() {
			const customerStore = useCustomerStore();
			// Do not attempt to fetch if not logged in
			if (!customerStore.isLoggedIn) return;

			return global.axios.get(process.env.VUE_APP_API_URL + '/v2/account/offers').then((response) => {
				customerStore.customer.offers = response.data;
				return Promise.resolve(response);
			}).catch((error) => {
				return Promise.reject(error.response);
			});
		},
		fetchBonusHistory(payload) {
			this.bonus_history_loading = true;
			const customerStore = useCustomerStore();
			// Do not attempt to fetch if not logged in
			if (!customerStore.isLoggedIn) return;

			return global.axios.get(process.env.VUE_APP_API_URL + '/v2/account/bonus-history', { params: { page: payload.page } }).then((response) => {
				customerStore.customer.offers.customer_bonus_history = response.data;
				this.bonus_history_loading = false;
				return Promise.resolve(response);
			}).catch((error) => {
				this.bonus_history_loading = false;
				return Promise.reject(error.response);
			});
		},
		fetchFreebetsHistory(payload) {
			this.freebet_history_loading = true;
			const customerStore = useCustomerStore();
			// Do not attempt to fetch if not logged in
			if (!customerStore.isLoggedIn) return;

			return global.axios.get(process.env.VUE_APP_API_URL + '/v2/account/bonus-freebets-history', { params: { page: payload.page } }).then((response) => {
				customerStore.customer.offers.customer_freebets_history = response.data;
				this.freebet_history_loading = false;
				return Promise.resolve(response);
			}).catch((error) => {
				this.freebet_history_loading = false;
				return Promise.reject(error.response);
			});
		},
		fetchFreespinsHistory(payload) {
			this.freespin_history_loading = true;
			const customerStore = useCustomerStore();
			// Do not attempt to fetch if not logged in
			if (!customerStore.isLoggedIn) return;

			return global.axios.get(process.env.VUE_APP_API_URL + '/v2/account/bonus-freespins-history', { params: { page: payload.page } }).then((response) => {
				customerStore.customer.offers.customer_freespins_history = response.data;
				this.freespin_history_loading = false;
				return Promise.resolve(response);
			}).catch((error) => {
				this.freespin_history_loading = false;
				return Promise.reject(error.response);
			});
		},
		fetchFreespinsActive(payload) {
			this.freespin_active_loading = true;
			const customerStore = useCustomerStore();
			// Do not attempt to fetch if not logged in
			if (!customerStore.isLoggedIn) return;

			return global.axios.get(process.env.VUE_APP_API_URL + '/v2/account/bonus-freespins-active', { params: { page: payload.page } }).then((response) => {
				customerStore.customer.offers.customer_freespins_history = response.data;
				this.freespin_active_loading = false;
				return Promise.resolve(response);
			}).catch((error) => {
				this.freespin_active_loading = false;
				return Promise.reject(error.response);
			});
		},
		claimManualBonus(payload) {
			return global.axios.post(process.env.VUE_APP_API_URL + '/account/bonus/claim', { bonus_id: payload.bonus_id }).then(response => {
				return Promise.resolve(response);
			}).catch(error => {
				return Promise.reject(error.response);
			});
		},
		cancelCustomerBonus(bonus_id) {
			return global.axios.post(process.env.VUE_APP_API_URL + '/account/bonus/cancel', { customer_bonus_id: bonus_id }).then(response => {
				return Promise.resolve(response);
			}).catch(error => {
				return Promise.reject(error.response);
			});
		},
		fetchAvailableFreebets(payload) {
			this.freebet_history_loading = true;

			return global.axios.get(process.env.VUE_APP_API_URL + '/v2/account/available-freebets', { params: { page: payload.page } }).then((response) => {
				this.freebet_history_loading = false;
				return Promise.resolve(response);
			}).catch((error) => {
				this.freebet_history_loading = false;
				return Promise.reject(error.response);
			});
		},
		fetchAvailableFreespins(payload) {
			this.freespin_history_loading = true;

			return global.axios.get(process.env.VUE_APP_API_URL + '/v2/account/available-freespins', { params: { page: payload.page } }).then((response) => {
				this.freespin_history_loading = false;
				return Promise.resolve(response);
			}).catch((error) => {
				this.freespin_history_loading = false;
				return Promise.reject(error.response);
			});
		}
	},
})