<script setup>
/* eslint-disable */
import { ref, onMounted, watch } from "vue";
import { useRouter } from 'vue-router';
import { useFixtureStore } from '@/stores/fixture';
import LeftSidebarFixture from '@/components/live_sports/left_sidebar/LeftSidebarFixture.vue';
const fixtureStore = useFixtureStore();
const router = useRouter();

const competition_opened = ref(true);

const props = defineProps({
	primary_overview_market: {
		required: true,
	},
	competition: {
		type: Object,
		required: true,
	},
	competitions_opened: {
		required: true,
	}
});

onMounted(() => {
	if (fixtureStore.fixtureBook.competition_id == props.competition[0].competition_id) competition_opened.value = true;
	competition_opened.value = props.competitions_opened;
})

watch(() => props.competitions_opened, (newValue, oldValue) => {
	competition_opened.value = newValue;
});

</script>
<template>
	<div class="accordion-item" :class="{'active': competition_opened}">
		<div class="accordion-header" @click="competition_opened = !competition_opened">
			<div class="country-info-inner">
				<div class="country-left">
					<!-- <div class="country-flag">
						<img :src="'/assets' + competition[0].region_icon_url" alt="flag-country">
					</div> -->
					<div class="country-name">
						<h3>{{ competition[0].region_name }} - {{ competition[0].competition_name }} </h3>
					</div>
				</div>
				<div class="country-right">
					<p>({{ competition.length }})</p>
				</div>
			</div>
		</div>
		<div class="accordion-content">
			<div class="sidebar-bet-info round-game-outer" v-for="(fixture, index) in competition" :key="index" :class="{'active': fixture.id == fixtureStore.fixtureBook.id }" :id="fixture.id == fixtureStore.fixtureBook.id ? 'thechosenone': ''">
				<LeftSidebarFixture :fixture="fixture" :primary_overview_market="primary_overview_market"/>
			</div>
		</div>
	</div>
</template>

<style scoped>
</style>