<script setup>
import { ref, onMounted, watch } from "vue";
import { useMenuStore } from "@/stores/menus";
import { useInitializeStore } from '@/stores/initialize';
import Cookie from '../src/components/modals/Cookie.vue';
import { usePreSportsStore } from '@/stores/pre_sports';
import { useFooterSectionStore } from '@/stores/footer_sections';
import { useMissionsStore } from '@/stores/missions';
import { useLanguagesStore } from "./stores/languages";
import { useCustomerStore } from "./stores/customer";

const initializeStore = useInitializeStore();
const menuStore = useMenuStore();
const footerSectionStore = useFooterSectionStore();
const device_type = ref(menuStore.isMobile == true ? 'mobile' : 'desktop')
const missionsStore = useMissionsStore();
const languagesStore = useLanguagesStore();
const customerStore = useCustomerStore();

watch(() => device_type.value, (newValue) => {
	if (newValue) {
		menuStore.fetchMenus(device_type.value)
	}
})

onMounted(() => {
	usePreSportsStore().fetchPreSports();

	if (customerStore.isLoggedIn) missionsStore.fetchCustomerSportsbookMissions();
	else missionsStore.fetchSportsbookMissions(languagesStore.selected_language.id);

	window.addEventListener("resize", () => {
		initializeStore.updateScreenWidth();
	});

	menuStore.fetchMenus(device_type.value);
	footerSectionStore.fetchFooterSections();
})

</script>
<template>
	<RouterView />
	<ModalTarget/>
	<Cookie />
</template>
