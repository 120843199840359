import _ from 'lodash';
import { defineStore } from 'pinia';

export const useMissionsStore = defineStore('missions', {
	state: () => ({
		selected_mission: null,
		mission_check: null,
		sportsbook_missions: [],
	}),
	getters: {
		getSelectedMission: (state) => {
			if (state.selected_mission) {
				return _.find(state.sportsbook_missions, { id: state.selected_mission });
			}
		},
	},
	actions: {
		fetchCustomerSportsbookMissions() {
			return global.axios.post(process.env.VUE_APP_API_URL + '/missions/sportsbook/fetch/customer').then((response) => {
				this.sportsbook_missions = response.data.sportsbook_missions;

				return Promise.resolve(response.data);
			}).catch((error) => {
				return Promise.reject(error.response);
			})
		},
		fetchSportsbookMissions(language_id) {
			return global.axios.post(process.env.VUE_APP_API_URL + '/missions/sportsbook/fetch', { language_id: language_id }).then((response) => {
				this.sportsbook_missions = response.data.sportsbook_missions;

				return Promise.resolve(response.data);
			}).catch((error) => {
				return Promise.reject(error.response);
			})
		},
		claimMission(mission_id) {
			return global.axios.post(process.env.VUE_APP_API_URL + '/missions/sportsbook/claim', { mission_id: mission_id }).then((response) => {

				return Promise.resolve(response.data);
			}).catch((error) => {
				return Promise.reject(error.response);
			})
		},
		checkMission(mission_id) {
			return global.axios.post(process.env.VUE_APP_API_URL + '/missions/sportsbook/check', { mission_id: mission_id }).then((response) => {
				this.mission_check = response.data;

				return Promise.resolve(response.data);
			}).catch((error) => {
				return Promise.reject(error.response);
			})
		},
		setSelectedMission(mission_id) {
			this.selected_mission = mission_id;
		}
	},
})