 /* eslint-disable */
import { defineStore } from 'pinia';
import _ from 'lodash';
import { applyPatch } from 'rfc6902';

export const usePreSportsStore = defineStore('preSports', {
	state: () => ({
		pre_sports: null,
		selected_pre_sport_id: null,
		selected_esport_id: null
	}),
	getters: {
		preSports(state) {
			return _.orderBy(state.pre_sports, 'id');
		},
		esports(state) {
			let esport_ids = [16, 21, 23, 27, 29]
			let esports = _.filter(state.pre_sports, sport => esport_ids.includes(sport.id));
			return _.orderBy(esports, 'id');
		},
		selectedPreSportId(state) {
			return state.selected_pre_sport_id;
		},
		selectedEsportId(state) {
			return state.selected_esport_id;
		},
		sportSlug: () => (pre_sport_id) => {
			let sport_slugs = {
				1: 'soccer',
				2: 'tennis',
				3: 'basketball',
				4: 'ice_hockey',
				5: 'handball',
				6: 'baseball',
				7: 'waterpolo',
				8: 'volleyball',
				9: 'badminton',
				10: 'table_tennis',
				11: 'futsal',
				12: 'boxing',
				13: 'american_football',
				14: 'horse_racing',
				15: 'snooker',
				16: 'counter_strike',
				17: 'mma',
				18: 'politics',
				19: 'archery',
				20: 'greyhounds',
				21: 'dota_2',
				22: 'cricket',
				23: 'valorant',
				24: 'rugby_union',
				25: 'floorball',
				26: 'curling',
				27: 'league_of_legends',
				28: 'chess',
				29: 'king_of_glory',
				30: 'hockey',
				31: 'bowls',
				32: 'pistol_shooting',
				33: 'beach_volleyball',
				34: 'trotting',
				35: 'formula_1',
				36: 'darts',
				37: 'golf',
				38: 'archery_shooting',
				39: 'aussie_rules',
				40: 'rugby_league',
				41: 'beach_soccer',
				42: 'netball',
				43: 'squash',
				44: 'cycling',
				45: 'gaelic_football',
				46: 'shooting',
				48: 'lacrosse',
			}

			return sport_slugs[pre_sport_id];
		},
		preSportName: (state) => (pre_sport_id) => {
			let sport_names = {
				1: 'Soccer',
				2: 'Tennis',
				3: 'Basketball',
				4: 'Ice Hockey',
				5: 'Handball',
				6: 'Baseball',
				7: 'Water Polo',
				8: 'Volleyball',
				9: 'Badminton',
				10: 'Table Tennis',
				11: 'Futsal',
				12: 'Boxing',
				13: 'American Football',
				14: 'Horse Racing',
				15: 'Snooker',
				16: 'Counter Strike',
				17: 'MMA',
				18: 'Politics',
				19: 'Archery',
				20: 'Greyhounds',
				21: 'Dota 2',
				22: 'Cricket',
				23: 'Valorant',
				24: 'Rugby Union',
				25: 'Floorball',
				26: 'Curling',
				27: 'League of Legends',
				28: 'Chess',
				29: 'King of Glory',
				30: 'Hockey',
				31: 'Bowls',
				32: 'Pistol Shooting',
				33: 'Beach Volleyball',
				34: 'Trotting',
				35: 'Formula 1',
				36: 'Darts',
				37: 'Golf',
				38: 'Archery Shooting',
				39: 'Aussie Rules',
				40: 'Rugby League',
				41: 'Beach Soccer',
				42: 'Netball',
				43: 'Squash',
				44: 'Cycling',
				45: 'Gaelic Football',
				46: 'Shooting',
				48: "Lacrosse",
			}

			return sport_names[pre_sport_id];
		},
		preSportWhiteIcon: (state) => (pre_sport_id) => {
			return '/assets/white_sport_icons/' + state.preSportName(pre_sport_id) + '.svg'
		},
		preSportGreenIcon: (state) => (pre_sport_id) => {
			return '/assets/green_sport_icons/Sport icons Green_' + state.preSportName(pre_sport_id) + '.svg'
		},
		preSportIcons: (state) => (pre_sport_id) => {
			if (!pre_sport_id) pre_sport_id = 1;
			let sport_icons = {
				default : '/assets/icons/sports/white/' + state.sportSlug(pre_sport_id) + '.svg',
				selected: '/assets/icons/sports/purple/' + state.sportSlug(pre_sport_id) + '.svg',
			}

			return sport_icons;
		},
		getBannerImage: (state) => (pre_sport_id) => {
			if (!pre_sport_id) pre_sport_id = 1;
			return '/assets/images/scoreboards/' + state.sportSlug(pre_sport_id) + '.jpg';
		},
	},
	actions: {
		fetchPreSports() {
			return global.axios.get(process.env.VUE_APP_SPORTSBOOK_API_URL + '/sports/pre', { withCredentials: false }).then((response) => {
				this.pre_sports = response.data;
				return Promise.resolve(response.data);
			}).catch((error) => {
				return Promise.reject(error);
			})
		},
		setSelectedPreSportId(pre_sport_id) {
			this.selected_pre_sport_id = Number(pre_sport_id);
		},
		setSelectedEsportId(esport_id) {
			this.selected_esport_id = Number(esport_id);
		},
		emptyPreSports() {
			// this.pre_sports = null;
			this.selected_pre_sport_id = null;
		}
	},
})